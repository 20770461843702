import React from 'react';
import ReactCrop from 'react-image-crop';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';
import getCroppedImg from './Cropper';
import 'react-image-crop/dist/ReactCrop.css';
import Button from '../../Venti-UI-Kit/Buttons/Button';
import styles from './ImageCrop.styles';

class ImageCrop extends React.Component {
  constructor(props) {
    super(props);
    const minimumCropPercentage = 0.1;
    this.minimumCropWidth = props.width ? props.width * minimumCropPercentage : 50;
    this.minimumCropHeight = props.height ? props.height * minimumCropPercentage : 50;

    const crop = this.props.width
      ? {
          unit: 'px',
          aspect: props.aspect,
          width: this.minimumCropWidth,
          height: this.minimumCropHeight,
          x: 25,
          y: 25,
        }
      : { unit: '%', width: 50, height: 50, x: 25, y: 25 };

    this.state = {
      crop,
      loadButtonDisabled: false,
    };
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  getCoordinatesPercentage(image, crop) {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const width = Math.floor(crop.width * scaleX);
    const height = Math.floor(crop.height * scaleY);
    const x = Math.floor(crop.x * scaleX);
    const y = Math.floor(crop.y * scaleY);
    return { width, height, x, y };
  }

  getCoordinatesPixels(crop) {
    const width = Math.floor(crop.width);
    const height = Math.floor(crop.height);
    const x = Math.floor(crop.x);
    const y = Math.floor(crop.y);
    return { width, height, x, y };
  }

  makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      let croppedImageUrl;

      if (this.props.fixed) {
        croppedImageUrl = this.getCoordinatesPixels(crop);
      } else {
        croppedImageUrl = this.getCoordinatesPercentage(this.imageRef, crop);
      }

      this.setState({ croppedImageUrl });
    }
  }

  async cropImage() {
    try {
      this.setState({ loadButtonDisabled: true });
      const croppedImage = await getCroppedImg(this.props.image, this.state.croppedImageUrl, 0);
      this.props.uploadPicture(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { crop } = this.state;
    const { classes } = this.props;

    if (this.props.image) {
      return (
        <Backdrop className={classes.backdrop} open>
          <ReactCrop
            src={this.props.image}
            className={classes.cropContainer}
            crop={crop}
            keepSelection
            locked={this.props.fixed}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            minWidth={this.minimumCropWidth}
            minHeight={this.minimumCropHeight}
            imageStyle={{ width: '100%', height: '100%' }}
          />
          <div className={classes.buttonsContainer}>
            <Button color="white" onClick={this.props.cancel}>
              Cancelar
            </Button>
            <Button onClick={this.cropImage.bind(this)} disabled={this.state.loadButtonDisabled}>
              Guardar
            </Button>
          </div>
        </Backdrop>
      );
    }
    return null;
  }
}
export default withStyles(styles)(ImageCrop);
