import { createStyles } from '@material-ui/core';

const styles = () => {
  return createStyles({
    featureSectionContainer: {
      padding: '40px 0 0',
      backgroundColor: '#121212',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      overflow: 'hidden',
      position: 'relative',
    },
  });
};

export default styles;
