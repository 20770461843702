import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    buttonContainer: {
      '&.MuiButtonBase-root': {
        background: 'transparent',
        borderRadius: 8,
        padding: 10,
        height: 36,
        width: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid',
        cursor: 'pointer',

        '&:hover': {
          borderColor: `${theme.palette.primary.main} !important`,
          color: `${theme.palette.primary.main} !important`,
          backgroundColor: 'transparent',
        },
      },
    },
  });

export default styles;
