import { createStyles } from '@material-ui/core';

const TRANSITION = 'left 0.4s ease-out';
const MD_BREAKPOINT = 769;

const styles = (theme) =>
  createStyles({
    container: {
      zIndex: 30,
      position: 'relative',
      top: -24,
      display: 'flex',
      height: '100%',
      width: 223,

      [theme.breakpoints.down(MD_BREAKPOINT)]: {
        width: 0,
      },
    },
    closeContainer: {
      width: 0,
      [theme.breakpoints.up(MD_BREAKPOINT)]: {
        width: 'max-content',
      },
    },
    sidebarBackground: {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      backgroundColor: 'rgba(0,0,0,0.4)',
      border: 'none',
      zIndex: 31,
    },
    sidebarContainer: {
      display: 'flex',
      flexDirection: 'column',
      border: 'none',
      zIndex: 32,
      padding: 0,
      width: 'max-content',
      height: '100%',
      position: 'fixed',
      backgroundColor: 'unset',

      [theme.breakpoints.down(MD_BREAKPOINT)]: {
        width: 0,
      },
    },
    sidebarContentContainer: {
      backgroundColor: theme.palette.grey[50],
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      borderRight: '3px solid rgba(0,0,0,0.13)',
      padding: '40px 10px',
      rowGap: 40,
      width: 'max-content',
      position: 'relative',
    },
    listContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      rowGap: 10,
    },
    title: {
      fontWeight: 600,
      fontSize: 24,
      color: theme.palette.text.primary,
      width: 200,
    },
    listItemContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: 10,
      columnGap: 10,
      borderRadius: 8,
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      border: `1px solid transparent`,

      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    itemText: {
      fontSize: 16,
      fontWeight: 500,
    },
    icon: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      position: 'absolute',
      color: theme.palette.text.primary,
      transition: TRANSITION,
      top: 10,

      [theme.breakpoints.up(MD_BREAKPOINT)]: {
        display: 'none',
      },
    },
    menuIcon: {
      transition: 'left 0.2s ease',
      left: 10,
    },
    closeIcon: {
      left: '80%',
      zIndex: 50,
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    transitionToInactive: {
      transition: TRANSITION,
      left: -550,
    },
    transitionToActive: {
      transition: TRANSITION,
      left: 0,
      [theme.breakpoints.down(MD_BREAKPOINT)]: {
        borderRight: 'unset',
      },
    },
    absolute: {
      position: 'absolute',
    },
    hidden: {
      display: 'none',
    },
  });

export default styles;
