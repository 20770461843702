const styles = (theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    columnGap: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      rowGap: 20,
    },
  },
  latLongContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  latLong: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '48%',
    [theme.breakpoints.down('xs')]: {
      width: '98%',
      flexDirection: 'column',
    },
  },
  iconTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  rowImgContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 450,
    },
  },
  title: {
    width: '100%',
    fontWeight: '500',
    paddingLeft: 10,
  },
  button: {
    marginTop: 20,
    marginBottom: 30,
  },
  colContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  inputDate: {
    width: '100%',
  },
  ImgIcon: {
    display: 'flex',
    alignItems: 'left',
    width: '1.3em',
    height: '1.3em',
  },
  bannerContainer: {
    width: '60%',
    height: '200px',
    [theme.breakpoints.down('md')]: {
      height: '400px',
      width: '100%',
    },
    justifyContent: 'center',
  },
  imgPromoContainer: {
    height: '200px',
    width: '40%',
    justifyContent: 'center',
    marginLeft: 50,
    [theme.breakpoints.down('md')]: {
      height: '400px',
      width: '100%',
      margin: 0,
    },
  },
  paper: {
    borderRadius: 8,
    backgroundColor: theme.palette.grey[50],
    width: '100%',
    maxWidth: 900,
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  icon: {
    margin: theme.spacing(1),
    width: 80,
  },
  select: {
    backgroundColor: theme.palette.grey[200],
    width: '100%',
    borderRadius: 10,
    padding: '5px 10px',
    height: 40,
    '& .MuiInput-input': {
      padding: '6px 0 7px',
    },
  },
  doubleInputContainer: {
    display: 'flex',
    columnGap: 15,
    [theme.breakpoints.down('xs')]: {
      rowGap: 15,
      flexDirection: 'column',
    },
  },
  dateInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  dateTimeInputTitle: {
    alignSelf: 'start',
  },
  radiusButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 20,
    textAlign: 'start',
  },
  stopDateCheckBoxContainer: {
    marginTop: 20,
    display: 'flex',
    textAlign: 'start',
  },
  checkBoxText: {
    alignSelf: 'center',
  },
});

export default styles;
