import React, { useRef } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import MediumEventCard from '../MediumEventCard/MediumEventCard';
import styles from './NextEventsSectionStyles';
import SectionButton from '../SectionButton/SectionButton';
import LoadingArrows from '../../../../../Components/Assets/LoadingArrows/LoadingArrows';
import VentiBlueTape from '../../../../../Components/Assets/VentiBlueTape/VentiBlueTape';

const NextEventsSection = ({ classes, events, userAlias, showSectionButton = true }) => {
  const nextEventsSectionRef = useRef();

  return (
    <>
      {showSectionButton && (
        <>
          <VentiBlueTape />
          <SectionButton animationTime="55s" sectionRef={nextEventsSectionRef}>
            <Typography>
              NEXT ON VENTI <b>PRÓXIMAMENTE EN VENTI</b>
            </Typography>
          </SectionButton>
        </>
      )}
      <div className={classes.container}>
        {events?.map((event) => (
          <MediumEventCard event={event} key={event.id} id={event.id} userAlias={userAlias} />
        ))}
      </div>
      {showSectionButton && <LoadingArrows blackAndWhite={false} />}
    </>
  );
};

export default withStyles(styles)(NextEventsSection);
