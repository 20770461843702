import React, { useMemo, useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Box, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InputMask from 'react-input-mask';
import styles from './InputsStyles';
import { INPUT_TYPE } from '../../constants/types';
import VTypography from '../VTypography/VTypography';

const TextInput = ({
  classes,
  title,
  name,
  errorMessage,
  successMessage,
  leftIcon,
  type,
  onChange,
  onFocus,
  mask,
  upperCase = false,
  autoComplete,
  hint,
  flex,
  defaultValue,
  maxLength,
  variant = 'outlined',
  maskChar = '‎',
  required = false,
  disabled = false,
}) => {
  const isPasswordInput = type === INPUT_TYPE.PASSWORD;
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();

  const inputType = useMemo(() => {
    return type === INPUT_TYPE.PASSWORD && showPassword ? INPUT_TYPE.TEXT : type;
  }, [type, showPassword]);

  const handleFocus = (e) => {
    if (onFocus) onFocus(e);
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={classes.inputContainer} style={{ flex }}>
      {title && (
        <Box className="topInputBox">
          {leftIcon}
          <VTypography variant="body2">{title}</VTypography>
          {required && (
            <VTypography variant="body2" color={theme.palette.error.main}>
              *
            </VTypography>
          )}
        </Box>
      )}
      <Box display="flex" alignItems="center" className="middleInputBox">
        <InputMask
          mask={mask}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          maskChar={maskChar}
          defaultValue={defaultValue}
          disabled={disabled}
        >
          {() => (
            <input
              defaultValue={defaultValue}
              name={name}
              type={inputType}
              onChange={onChange}
              maxLength={maxLength}
              autoComplete={autoComplete}
              className={`inputElement ${variant} ${errorMessage && 'error'} ${
                successMessage && 'success'
              } ${disabled && 'disabled'}`}
              style={{
                ...(upperCase && { textTransform: 'uppercase' }),
              }}
              disabled={disabled}
            />
          )}
        </InputMask>
        {isPasswordInput && (
          <IconButton
            className={`rightIcon ${variant}`}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
          </IconButton>
        )}
      </Box>
      <Box className="bottomInputBox">
        <VTypography
          variant="caption"
          color={(errorMessage && 'error') || (successMessage && 'success')}
        >
          {errorMessage || successMessage || (isFocused && hint)}
        </VTypography>
      </Box>
    </div>
  );
};

export default withStyles(styles)(TextInput);
