import { useState } from 'react';
import { ORDER_STATUS } from '../../../constants/types';
import { useInfinitScroll } from '../../../hooks/useInfinitScroll';
import { translate } from '../../../utils/translate';
import { getStatusesParam, updateStatusesParam } from './EventOrdersPage.utils';
import api from '../../../api/api';

export const useEventOrdersPage = (eventId) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(getStatusesParam());

  const { items: orders, isGettingMoreItems } = useInfinitScroll({
    endpoint: api.getEventOrders,
    params: [eventId],
    options: { statuses: selectedOptions, searchQuery },
    itemsLimit: 10,
  });

  const orderStatusFilterOptions = Object.values(ORDER_STATUS).map((status) => ({
    label: translate(status),
    value: status,
  }));

  const onChange = ({ target: { value } }) => {
    setSelectedOptions(value);
    updateStatusesParam(value);
  };

  return {
    selectedOptions,
    orders,
    orderStatusFilterOptions,
    isGettingMoreItems,
    onChange,
    setSearchQuery,
  };
};
