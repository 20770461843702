import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Stack } from '@mui/material';
import EditProducerForm from '../../../Components/Forms/EditProducer/EditProducerForm';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { handleRequestHelper } from '../../../utils/helpers';
import history from '../../../appHistory';
import api from '../../../api/api';

class NewProducer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      producer: {},
      showErrors: false,
      errorStatusProducer: false,
      errorStatusUser: false,
    };
  }

  handleProducerChange = (producer) => {
    this.setState({ producer });
  };

  async uploadProducer() {
    if (this.state.errorStatusProducer || this.state.errorStatusUser || !this.validateProducer()) {
      this.setState({ showErrors: true });
      this.props.showNotification('Existen errores en los campos', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    await handleRequestHelper({
      endpoint: () => api.createProducer(this.state.producer),
      showNotification: this.props.showNotification,
      onSuccess: ({ producer }) => {
        this.props.showNotification(
          'Se creó la productora! Redirgiendo...',
          NOTIFICATION_VARIANT.SUCCESS
        );

        setTimeout(() => {
          history.push(`/backoffice/productoras/${producer.id}`);
        }, 1000);
      },
    });
  }

  validateProducer() {
    const p = this.state.producer;
    const fields = [p.name, p.theme];

    if (fields.some((field) => !field)) {
      this.props.showNotification('Faltan completar campos', NOTIFICATION_VARIANT.ERROR);
      return false;
    }

    return true;
  }

  changeErrorStatusProducer(status) {
    this.setState({ errorStatusProducer: status });
  }

  render() {
    const { classes } = this.props;

    return (
      <PageContainer title="Backoffice - Crear Productora">
        <Stack alignItems="center" gap={3} px={2}>
          <Typography className={classes.title} component="h1" variant="h4">
            Crear productora
          </Typography>

          <EditProducerForm
            producer={this.state.producer}
            handleProducerChange={this.handleProducerChange.bind(this)}
            changeError={this.changeErrorStatusProducer.bind(this)}
            showErrors={this.state.showErrors}
          />

          <Button type="submit" color="main" onClick={this.uploadProducer.bind(this)}>
            Crear Productora
          </Button>
        </Stack>
      </PageContainer>
    );
  }
}

const styles = (theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
});

export default withStyles(styles)(NewProducer);
