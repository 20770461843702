import { MenuItem, Stack } from '@mui/material';
import React from 'react';
import { useTheme } from '@material-ui/core';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';

const UserMenuItem = ({ label, color, variant = 'body2', rightIcon, leftIcon, onClick }) => {
  const theme = useTheme();
  const notClickableStyle = !onClick && {
    '&:hover': { backgroundColor: 'unset', cursor: 'default', userSelect: 'auto' },
    '&:focus': { backgroundColor: 'unset' },
  };

  return (
    <MenuItem dense onClick={onClick} sx={notClickableStyle} disableTouchRipple={!onClick}>
      <Stack
        direction="row"
        width="100%"
        color={color}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <VIcon name={leftIcon} sx={{ color: color || theme.palette.grey[500] }} />
          <VTypography color={color} variant={variant} align="center">
            {label}
          </VTypography>
        </Stack>
        <VIcon name={rightIcon} />
      </Stack>
    </MenuItem>
  );
};

export default UserMenuItem;
