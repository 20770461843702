import React from 'react';
import { withStyles, useTheme } from '@material-ui/core';
import { IconButton } from '@mui/material';
import styles from './IconButton.styles';

const VIconButton = ({ classes, borderColor, color, Icon, onClick, disabled }) => {
  const theme = useTheme();

  return (
    <IconButton
      className={classes.buttonContainer}
      style={{
        borderColor:
          borderColor || (disabled && theme.palette.grey[300]) || theme.palette.grey[400],
        color: color || (disabled && theme.palette.grey[300]) || theme.palette.grey[800],
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon}
    </IconButton>
  );
};

export default withStyles(styles)(VIconButton);
