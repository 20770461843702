import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './FeaturedEventsSectionStyles';
import Carousel from '../../../../../Components/Carousel/Carousel';
import FeaturedEventCard from '../FeaturedEventCard/FeaturedEventCard';
import Clouds from '../../../../../Components/Assets/Clouds/Clouds';

const HEADER_HEIGHT = 64;
const SECTION_BUTTON_HEIGHT = 58;
const VENTI_BLUE_TAPE_HEIGHT = 48;

const FeaturedEventsSection = ({ classes, events, userAlias }) => {
  const sectionHeight =
    window.innerHeight - HEADER_HEIGHT - SECTION_BUTTON_HEIGHT - VENTI_BLUE_TAPE_HEIGHT;

  return (
    <div className={classes.featureSectionContainer} style={{ height: sectionHeight }}>
      <Carousel>
        {events?.map((event) => (
          <FeaturedEventCard event={event} key={event.id} userAlias={userAlias} />
        ))}
      </Carousel>
      <Clouds />
    </div>
  );
};

export default withStyles(styles)(FeaturedEventsSection);
