import { createStyles } from '@material-ui/core';

const styles = (theme) => {
  return createStyles({
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minHeight: 56,
      color: theme.palette.text.primary,

      '& .error': {
        outline: `1px solid ${theme.palette.error.main}`,
      },

      '& .success': {
        outline: `1px solid ${theme.palette.success.main}`,
      },

      '& .topInputBox': {
        marginBottom: 8,
        height: 16,
        display: 'flex',
        gap: 4,
        alignItems: 'center',
      },

      '& .bottomInputBox': {
        marginTop: 4,
        minHeight: 16,
      },

      '& .middleInputBox': {
        position: 'relative',
        height: 40,
      },

      '& .inputElement': {
        ...theme.typography.body1,
        width: '100%',
        height: '100%',
        borderRadius: 8,
        padding: '8px 16px',
        border: 'none',

        '&.outlined': {
          color: theme.palette.text.primary,
          background: theme.palette.grey[50],
          outline: `1px solid ${theme.palette.grey[300]}`,

          '&:focus': {
            outline: `2px solid ${theme.palette.primary.main}`,
          },
        },

        '&.disabled': {
          background: theme.palette.grey[200],
          color: theme.palette.grey[600],

          '&:hover': {
            outline: `1px solid ${theme.palette.grey[300]}`,
          },
        },

        '&:hover': {
          outline: `1px solid ${theme.palette.primary.main}`,
        },
      },

      '& .rightIcon': {
        position: 'absolute',
        padding: 0,
        right: 16,
        border: 'none',
        background: 'transparent',
      },
    },
  });
};

export default styles;
