import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import styles from './EventList.styles';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import { SORT_DIRECTION } from '../../../constants/types';
import permissions from '../../../Providers/Permissions/Permissions';
import EventListItem from '../../ListsItems/EventListItem/EventListItem';
import { sortObjectArray, copyToClipboard, getUserHomeLink } from '../../../utils/utils';
import Searcher from '../../Backoffice/Searcher/Searcher';
import LoadingListContent from '../../LoadingListContent/LoadingListContent';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import api from '../../../api/api';

const EventList = ({ classes, producer, past = false }) => {
  const { showNotification } = useNotifications();
  const { user } = useAuth();
  const { role } = user;
  const { id: producerId } = producer;
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const sortAndFilterEvents = (events) => {
    const currentDate = new Date().toISOString();
    return sortObjectArray(
      events.filter(({ endDate }) => (past ? endDate < currentDate : endDate >= currentDate)),
      'startDate',
      past ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC
    );
  };

  const getEvents = async (searchQuery) => {
    setLoading(true);
    await handleRequestHelper({
      endpoint: () => api.getPrivateEvents(producerId, searchQuery),
      onSuccess: ({ events }) => setEvents(sortAndFilterEvents(events)),
      showNotification,
    });
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className={classes.container}>
      {permissions.isAdmin(role) && !past && (
        <div className={classes.events}>
          <Button
            href={`/backoffice/productoras/${producerId}/eventos/crear`}
            className={classes.newEventButton}
          >
            Crear Evento Nuevo
          </Button>
        </div>
      )}
      {permissions.isSeller(role) && !past && (
        <div className={classes.events}>
          <Button
            onClick={() => copyToClipboard(getUserHomeLink(producer, user), showNotification)}
            className={classes.newEventButton}
          >
            Compartir todos mis eventos
          </Button>
        </div>
      )}
      <Searcher onSubmit={getEvents} placeholder="Buscar evento" />
      <LoadingListContent
        loading={loading}
        list={events}
        notFoundMessage="No se encontraron eventos"
      >
        {events.map((event) => (
          <EventListItem key={event.id} event={event} />
        ))}
      </LoadingListContent>
    </div>
  );
};

export default withStyles(styles)(EventList);
