import React from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import { withRouter } from 'react-router-dom';
import styles from './EventCard.styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { formatCattaneoEventCardDate, formatEventCardDate } from './EventCard.utils';
import history from '../../../../appHistory';

export const HERNAN_CATTANEO_15_16_EVENT_ID = 2520;
export const HERNAN_CATTANEO_PARKING_15_16_EVENT_ID = 2950;

const EventCard = ({ classes, event }) => {
  const { promoImg, name, placeName, startDate, id: eventId } = event;
  const theme = useTheme();

  return (
    <Box
      className={classes.eventCardContainer}
      style={{ backgroundImage: `url(${promoImg})` }}
      onClick={() => history.push(`/mis-tickets/${eventId}`)}
    >
      <Box className={classes.gradient} />
      <Box zIndex={1}>
        <VTypography
          variant="h3"
          fixedFontSize={24}
          color={theme.palette.common.white}
          linesNumber={2}
        >
          {name}
        </VTypography>
        <VTypography variant="body2" color={theme.palette.common.white} linesNumber={1}>
          {placeName}
        </VTypography>
        <VTypography variant="subtitle3" fixedFontSize={16} color={theme.palette.common.white}>
          {eventId !== HERNAN_CATTANEO_15_16_EVENT_ID &&
          eventId !== HERNAN_CATTANEO_PARKING_15_16_EVENT_ID
            ? formatEventCardDate(startDate)
            : formatCattaneoEventCardDate(startDate)}
        </VTypography>
      </Box>
    </Box>
  );
};

export default withRouter(withStyles(styles)(EventCard));
