import React from 'react';
import { withStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import styles from './PageContainerStyles';
import Header, { HEADER_CHILDREN_HEIGHT, HEADER_HEIGHT } from '../Header/Header';
import Footer from '../Footer/Footer';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CustomCircularProgress from '../CustomCircularProgress/CustomCircularProgress';

const PageContainer = ({
  classes,
  children,
  withFooter = false,
  withPaddingTop = true,
  isLoading = false,
  showLoginButton = true,
  showSearchButton = false,
  headerChildren,
  title = 'Venti',
}) => {
  const { height } = useWindowDimensions();
  const headerHeight = headerChildren ? HEADER_HEIGHT + HEADER_CHILDREN_HEIGHT : HEADER_HEIGHT;

  return (
    <div className={classes.pageContainer}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header showSearchButton={showSearchButton} showLoginButton={showLoginButton}>
        {headerChildren}
      </Header>
      <div
        className={classes.pageContent}
        style={{
          minHeight: height,
          paddingTop: withPaddingTop ? headerHeight + 24 : headerHeight,
        }}
      >
        {isLoading ? <CustomCircularProgress /> : children}
      </div>

      {withFooter && <Footer />}
    </div>
  );
};

export default withStyles(styles)(PageContainer);
