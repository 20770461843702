import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.grey[200],
      borderRadius: '10px',
      border: '2px solid transparent',
      transition: 'background-color 0.25s, border-color 0.25s',
      padding: '0 10px',
      minHeight: 40,
      '& input': {
        padding: 5,
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
    errorRoot: {
      backgroundColor: 'transparent',
      border: '2px solid red',
    },
    focused: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.secondary.main,
    },
    helper: {
      color: 'red',
      height: 15,
      display: 'inline',
      float: 'left',
    },
    length: {
      color: theme.palette.text.color,
      padding: 5,
      textAlign: 'end',
      paddingRight: 10,
    },
    multilineInputStyle: {
      '& .MuiInputBase-inputMultiline': {
        padding: '6px 0 7px',
      },
    },
  });

export default styles;
