import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    section: {
      position: 'sticky',
      zIndex: 10,
      width: '100%',
      height: 58,
      display: 'flex',
      textDecoration: 'none',
      padding: 0,
      alignItems: 'center',
      flexDirection: 'column',
      cursor: 'pointer',
      border: 'none',
      borderBottom: `0.5px solid ${theme.palette.background.toolbar}`,
      borderTop: `0.5px solid ${theme.palette.background.toolbar}`,
      backgroundColor: theme.palette.background.default,
      overflow: 'hidden',
    },
    marquee: {
      flex: 1,
      borderBottom: `0.5px solid ${theme.palette.background.toolbar}`,
      color: theme.palette.text.primary,
      '& p': {
        fontSize: 20,
      },
      '& :hover, & :active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    refDiv: {
      scrollMargin: '58px',
    },
  });

export default styles;
