import {
  ORDER_STATUS,
  ORDER_TYPE,
  QR_STATUS,
  TICKET_TYPE,
  TICKET_ACTIVITY_STATUS,
  TICKET_VISIBILITY_MODE,
  USER_ROLES,
  GENDER,
} from '../constants/types';

const TRANSLATIONS = {
  // Ticket Types Statuses
  [TICKET_ACTIVITY_STATUS.ACTIVE]: 'Activo',
  [TICKET_ACTIVITY_STATUS.ARCHIVED]: 'Eliminado',
  [TICKET_ACTIVITY_STATUS.INACTIVE]: 'Inactivo',

  // Ticket Types Modes
  [TICKET_VISIBILITY_MODE.VISIBLE]: 'Visible',
  [TICKET_VISIBILITY_MODE.HIDDEN]: 'Oculto',

  // Ticket Types
  [TICKET_TYPE.PRESENTIAL]: 'Estándar',
  [TICKET_TYPE.TABLE]: 'Acceso múltiple',
  [TICKET_TYPE.INVITATION]: 'Invitación',

  // User Roles
  [USER_ROLES.ADMIN]: 'Administrador',
  [USER_ROLES.SUPER_ADMIN]: 'Super Administrador',
  [USER_ROLES.READ_ONLY_ADMIN]: 'Solo lectura de reportes',
  [USER_ROLES.SELLER]: 'Vendedor',
  [USER_ROLES.SELLER_WITH_CASH]: 'Vendedor con venta cash',
  [USER_ROLES.VALIDATOR]: 'Validador',

  // User Genders
  [GENDER.MALE]: 'Masculino',
  [GENDER.FEMALE]: 'Femenino',
  [GENDER.OTHER]: 'Otro',

  // Order statuses
  [ORDER_STATUS.PAID]: 'Pagada',
  [ORDER_STATUS.PENDING]: 'Pendiente',
  [ORDER_STATUS.REFUNDED_WITHOUT_FEE]: 'Devolución Parcial',
  [ORDER_STATUS.REFUNDED_WITH_FEE]: 'Devolución Total',
  [ORDER_STATUS.CANCELED]: 'Inhabilitada',
  [ORDER_STATUS.NOT_PAID]: 'Vencida',

  [QR_STATUS.USED]: 'Usado',

  [ORDER_TYPE.ONLINE]: 'Online',
  [ORDER_TYPE.CASH]: 'Venta Cash',
  [ORDER_TYPE.FREE]: 'Gratis',
  [ORDER_TYPE.INVITATION]: 'Invitación',
};

export const translate = (word) => {
  return TRANSLATIONS[word];
};
