import React from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import styles from './VTypography.styles';

const VTypography = ({
  classes,
  style,
  children,
  linesNumber,
  variant,
  className = '',
  align = 'left',
  mb,
  mt,
  color,
  width,
  fixedFontSize,
}) => {
  return (
    <Typography
      className={`${className} ${classes.base} ${classes[variant]} ${classes[color]}`}
      style={{
        lineClamp: `${linesNumber}`,
        WebkitLineClamp: `${linesNumber}`,
        width,
        marginBottom: mb,
        color,
        marginTop: mt,
        fontSize: fixedFontSize,
        ...style,
      }}
      align={align}
    >
      {children}
    </Typography>
  );
};

export default withStyles(styles)(VTypography);
