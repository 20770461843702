import React from 'react';
import { Button as MuiButton, withStyles } from '@material-ui/core';
import { CircularProgress, Stack } from '@mui/material';
import styles from './ButtonStyles';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const Button = ({
  classes,
  title = 'Button',
  variant = 'contained',
  fullwidth = true,
  loading = false,
  target = '_blank',
  size = 'medium',
  onClick,
  disabled,
  color = 'main',
  href,
  icon,
  width,
  type,
}) => {
  const buttonClassName = `${classes.base} ${classes[variant]} ${classes[size]} ${
    !disabled ? color : 'disabled'
  } ${fullwidth ? classes.fullwidth : ''}`;

  return (
    <MuiButton
      variant={variant}
      className={buttonClassName}
      disabled={disabled || loading}
      onClick={onClick}
      target={href && target}
      href={href}
      type={type}
      style={{ width, minWidth: width }}
    >
      {loading ? (
        <CircularProgress className="buttonText" size={20} />
      ) : (
        <Stack gap={1} alignItems="center" direction="row" className="buttonText">
          {icon && icon}
          <VTypography variant="button1">{title}</VTypography>
        </Stack>
      )}
    </MuiButton>
  );
};
export default withStyles(styles)(Button);
