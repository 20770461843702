import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Checkbox } from '@material-ui/core';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import { Stack } from '@mui/material';
import styles from './EditEventForm.styles';
import { icons } from '../../../Providers/Icons/IconsProvider';
import ImageDropzone from '../../ImageDropzone/ImageDropzone';
import { getRadioHelperText } from '../../../Venti-UI-Kit/RadioButtons/Errors';
import {
  getTextHelperObligatoryField,
  getTextHelperText,
} from '../../../Venti-UI-Kit/Input/Errors';
import TitledRadioButtons from '../../../Venti-UI-Kit/RadioButtons/TitledRadioButtons';
import TitledInput from '../../../Venti-UI-Kit/Input/TitledInput';
import InputTitle from '../../InputTitle/InputTitle';
import CustomTimePicker from '../../DatePickerComponents/CustomTimePicker';
import CustomDatePicker from '../../DatePickerComponents/CustomDatePicker';
import StadiumCustomizer from '../../Backoffice/StadiumCustomizer/StadiumCustomizer';
import permissions from '../../../Providers/Permissions/Permissions';

const NO_STADIUM_ID = 0;

const EVENT_STATUS = [
  { value: 'active', label: 'Público' },
  { value: 'hidden', label: 'Privado' },
];

const BLACKLISTED = 'visible';
const NOT_BLACKLISTED = 'hidden';

const SELLER_BLACKLIST_STATUS = [
  { value: BLACKLISTED, label: 'Visible' },
  { value: NOT_BLACKLISTED, label: 'Oculto' },
];

class EditEventForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        name: false,
        status: false,
        promoImg: false,
        bannerImg: false,
        description: false,
        placeName: false,
        streamingPrivacy: false,
        streamingCode: false,
        chatCode: false,
        startDate: false,
        endDate: false,
        defaultStopOfSalesDate: false,
      },
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    let inputValue = value;
    const newEvent = this.props.event;
    if (value === '') inputValue = null;
    newEvent[name] = inputValue;
    this.props.handleEventChange(newEvent);
  };

  handleStadiumChange = ({ target }) => {
    const { value } = target;
    const newEvent = this.props.event;
    let stadium = null;
    if (value !== NO_STADIUM_ID) {
      const producersStadiumsCopy = JSON.parse(JSON.stringify(this.props.producerStadiums));
      stadium = producersStadiumsCopy.find(({ id }) => id === value);
    }
    newEvent.stadium = stadium;
    this.props.handleEventChange(newEvent);
  };

  handleSellersBlacklistChange = (event) => {
    const { value, name } = event.target;
    const newEvent = this.props.event;
    newEvent[name] = value === NOT_BLACKLISTED;
    this.props.handleEventChange(newEvent);
  };

  handleImgChange = (url, name) => {
    const newEvent = this.props.event;
    newEvent[name] = url;
    this.props.handleEventChange(newEvent);
  };

  handleDateChange = (date) => (value) => {
    const newEvent = this.props.event;
    newEvent[date] = value;
    this.props.handleEventChange(newEvent);
  };

  handleCheckBoxChange(checkBox, check) {
    const newEvent = this.props.event;
    newEvent[checkBox] = check;
    this.props.handleEventChange(newEvent);
  }

  changeErrorCurrentStatus(input, error, errorFields) {
    errorFields = Object.keys(errorFields);

    if (this.state.errors[input] !== error) {
      let showErrorsState = error;

      if (errorFields.includes(input)) {
        if (errorFields.length > 1) showErrorsState = true;
      }

      this.setState({ errors: { ...this.state.errors, [input]: error } });
      this.props.changeError(showErrorsState);
    }
  }

  checkInputError(value, input, getHelperText) {
    const helperText = getHelperText(value);
    const error = helperText !== '';

    const errorState = this.state.errors;
    const errorFields = Object.keys(errorState).reduce((arr, key) => {
      if (errorState[key]) arr[key] = errorState[key];
      return arr;
    }, {});

    this.changeErrorCurrentStatus(input, error, errorFields);

    return helperText;
  }

  render() {
    const { classes, user, edition, event, producerStadiums } = this.props;

    let producerStadiumOptions = [];
    if (producerStadiums && producerStadiums.length) {
      producerStadiumOptions = producerStadiums.map(({ name, capacity, numberOfSections, id }) => {
        const capacityLabel = permissions.isSuperAdmin(user.role) ? ` - ${capacity}p` : '';
        return {
          label: `${name} ${capacityLabel} - ${numberOfSections} sectores`,
          value: id,
        };
      });

      producerStadiumOptions.unshift({ label: 'Sin planimetria', value: NO_STADIUM_ID });
    }

    return (
      <Stack gap={3} alignItems="center" component="form" maxWidth={900} onSubmit={this.onSubmit}>
        {!!producerStadiumOptions.length && (
          <Paper elevation={0} className={classes.paper}>
            <InputTitle title="Tipo de evento" materialIcon={<EditLocationOutlinedIcon />} />
            <Select
              className={classes.select}
              name="producerStadiumId"
              value={event.stadium?.id || NO_STADIUM_ID}
              disableUnderline
              onChange={this.handleStadiumChange.bind(this)}
              disabled={edition}
            >
              {producerStadiumOptions.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Paper>
        )}

        {!!event.stadium?.id && (
          <Paper elevation={0} className={classes.paper}>
            <StadiumCustomizer
              event={event}
              handleEventChange={this.props.handleEventChange.bind(this)}
            />
          </Paper>
        )}
        <Paper elevation={0} className={classes.paper}>
          <TitledInput
            title="Título del Evento"
            name="name"
            autocomplete="off"
            value={event.name}
            icon={icons.title}
            autoFocus
            onChange={this.handleInputChange.bind(this)}
            helperText={this.checkInputError(event.name, 'name', getTextHelperText)}
            showErrors={this.props.showErrors}
          />
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <div className={classes.rowImgContainer}>
            <div className={classes.bannerContainer}>
              <div className={classes.iconTitle}>
                <img alt="icon" src={icons.image} className={classes.ImgIcon} />

                <Typography className={classes.title} align="left" variant="subtitle1">
                  Banner del Evento
                </Typography>
              </div>
              <ImageDropzone
                width={2600}
                height={1000}
                name="bannerImg"
                handleImgChange={this.handleImgChange.bind(this)}
                value={event.bannerImg}
              />
            </div>

            <div className={classes.imgPromoContainer}>
              <div className={classes.iconTitle}>
                <img alt="icon" src={icons.image} className={classes.ImgIcon} />

                <Typography className={classes.title} align="left" variant="subtitle1">
                  Imagen promo
                </Typography>
              </div>
              <ImageDropzone
                width={1000}
                height={1250}
                name="promoImg"
                handleImgChange={this.handleImgChange.bind(this)}
                value={event.promoImg}
              />
            </div>
          </div>
        </Paper>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.rowContainer}>
            <div className={classes.colContainer}>
              <div className={classes.iconTitle}>
                <img alt="icon" src={icons.eventDate} className={classes.ImgIcon} />

                <div>
                  <Typography className={classes.title} align="left" variant="subtitle1">
                    El Evento comienza
                  </Typography>
                </div>
              </div>

              <div className={classes.doubleInputContainer}>
                <div className={classes.dateInputContainer}>
                  <CustomDatePicker
                    value={event.startDate}
                    onChange={this.handleDateChange('startDate')}
                    helperText={this.checkInputError(
                      event.startDate,
                      'startDate',
                      getTextHelperObligatoryField
                    )}
                    showErrors={this.props.showErrors}
                  />
                </div>
                <div className={classes.dateInputContainer}>
                  <CustomTimePicker
                    value={event.startDate}
                    onChange={this.handleDateChange('startDate')}
                    helperText={this.checkInputError(
                      event.startDate,
                      'startDate',
                      getTextHelperObligatoryField
                    )}
                    showErrors={this.props.showErrors}
                  />
                </div>
              </div>
            </div>

            <div className={classes.colContainer}>
              <div className={classes.iconTitle}>
                <img alt="icon" src={icons.eventDate} className={classes.ImgIcon} />

                <div>
                  <Typography className={classes.title} align="left" variant="subtitle1">
                    El Evento termina
                  </Typography>
                </div>
              </div>
              <div className={classes.doubleInputContainer}>
                <div className={classes.dateInputContainer}>
                  <CustomDatePicker
                    value={event.endDate}
                    minDate={event.startDate}
                    onChange={this.handleDateChange('endDate')}
                    helperText={this.checkInputError(
                      event.endDate,
                      'endDate',
                      getTextHelperObligatoryField
                    )}
                    showErrors={this.props.showErrors}
                  />
                </div>
                <div className={classes.dateInputContainer}>
                  <CustomTimePicker
                    value={event.endDate}
                    minDate={event.startDate}
                    onChange={this.handleDateChange('endDate')}
                    helperText={this.checkInputError(
                      event.endDate,
                      'endDate',
                      getTextHelperObligatoryField
                    )}
                    showErrors={this.props.showErrors}
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <InputTitle
            icon={icons.date}
            title="Día y horario para la finalización de la venta de tickets"
            description="Seleccioná el momento en el que finalizarán la venta de tickets y el envío de accesos."
          />
          <div className={classes.doubleInputContainer}>
            <div className={classes.dateInputContainer}>
              <Typography className={classes.dateTimeInputTitle}>Fecha</Typography>
              <CustomDatePicker
                value={event.defaultStopOfSalesDate || null}
                onChange={this.handleDateChange('defaultStopOfSalesDate')}
                ampm={false}
                helperText={
                  !this.props.edition &&
                  this.checkInputError(
                    event.defaultStopOfSalesDate,
                    'defaultStopOfSalesDate',
                    getTextHelperObligatoryField
                  )
                }
                showErrors={!this.props.edition && this.props.showErrors}
              />
            </div>
            <div className={classes.dateInputContainer}>
              <Typography className={classes.dateTimeInputTitle}>Hora</Typography>
              <CustomTimePicker
                value={event.defaultStopOfSalesDate || null}
                onChange={this.handleDateChange('defaultStopOfSalesDate')}
                helperText={
                  !this.props.edition &&
                  this.checkInputError(
                    event.defaultStopOfSalesDate,
                    'defaultStopOfSalesDate',
                    getTextHelperObligatoryField
                  )
                }
                showErrors={!this.props.edition && this.props.showErrors}
              />
            </div>
          </div>
          <div className={classes.stopDateCheckBoxContainer}>
            <Checkbox
              checked={event.shouldExtendStopOfSalesDateToInvitations}
              onChange={() =>
                this.handleCheckBoxChange(
                  'shouldExtendStopOfSalesDateToInvitations',
                  !event.shouldExtendStopOfSalesDateToInvitations
                )
              }
              color="primary"
            />
            <Typography className={classes.checkBoxText}>
              También cortar el envío de invitaciones (Se recomienda marcar esta opción siempre que
              se realice la validación de entradas del evento con molinetes)
            </Typography>
          </div>
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <TitledInput
            title="Descripción del Evento"
            name="description"
            autocomplete="off"
            value={event.description}
            icon={icons.description}
            onChange={this.handleInputChange.bind(this)}
            multiline
            showErrors={this.props.showErrors}
          />
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <TitledInput
            title="Nombre del Lugar"
            name="placeName"
            autocomplete="off"
            value={event.placeName}
            icon={icons.location}
            onChange={this.handleInputChange.bind(this)}
            helperText={
              !this.props.edition &&
              this.checkInputError(event.placeName, 'placeName', getTextHelperObligatoryField)
            }
            showErrors={this.props.showErrors}
          />
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <div className={classes.radiusButtonContainer}>
            <TitledRadioButtons
              title="Visualización del Evento"
              subtitle={`Esta opción habilita que tu evento se muestre en el inicio.${
                !this.props.edition ? ' Luego podrás editar esta decisión.' : ''
              }`}
              name="status"
              icon={icons.ticket}
              currentValue={event.status}
              onChange={this.handleInputChange.bind(this)}
              values={EVENT_STATUS}
              helperText={this.checkInputError(event.status, 'status', getRadioHelperText)}
              showErrors={this.props.showErrors}
            />

            <TitledRadioButtons
              title="Accesibilidad del Evento (Sellers)"
              subtitle={`Esta opción oculta el evento a todos los sellers hasta que sea desactivada${
                !this.props.edition ? ' desde la pantalla de edición del evento' : ''
              }. Los administradores podrán visualizar el evento normalmente.`}
              name="shouldBlacklistSellers"
              icon={icons.ticket}
              currentValue={event.shouldBlacklistSellers ? NOT_BLACKLISTED : BLACKLISTED}
              onChange={this.handleSellersBlacklistChange.bind(this)}
              values={SELLER_BLACKLIST_STATUS}
            />
          </div>
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <Typography className={classes.title} align="left" variant="subtitle1">
            Productora asociada
          </Typography>
          <Select
            className={classes.select}
            id="producerId"
            name="producerId"
            value={event.producerId}
            defaultValue={user?.producers?.[0]?.id}
            onChange={this.handleInputChange.bind(this)}
            disableUnderline
          >
            {user.producers.map((producer) => {
              return (
                <MenuItem key={producer.id} value={producer.id}>
                  {producer.name}
                </MenuItem>
              );
            })}
          </Select>
        </Paper>
      </Stack>
    );
  }
}

export default withStyles(styles)(EditEventForm);
