import darkVentiTheme from '../themes/whiteLabels/darkVentiTheme';
import bnpTheme from '../themes/whiteLabels/bnpTheme';
import lightVentiTheme from '../themes/whiteLabels/lightVentiTheme';
import mandarineTheme from '../themes/whiteLabels/mandarineTheme';
import underClubTheme from '../themes/whiteLabels/underClubTheme';

export const WHITE_LABELS = {
  MANDARINE: 'mandarine',
  UNDER_CLUB: 'under-club',
  VENTI: 'venti',
  DARK_VENTI: 'dark-venti',
  BNP: 'bnp',
  HERNAN_CATTANEO: 'hernan-cattaneo',
};

export const PRODUCTION_URLS_BY_WHITE_LABEL = {
  [WHITE_LABELS.MANDARINE]: 'https://mandarinegroup.com.ar',
  [WHITE_LABELS.UNDER_CLUB]: 'https://tickets.underclub.com.ar',
  [WHITE_LABELS.VENTI]: 'https://venti.com.ar',
  [WHITE_LABELS.DARK_VENTI]: 'https://venti.com.ar',
  [WHITE_LABELS.BNP]: 'https://tickets.buenasnochespro.com',
  [WHITE_LABELS.HERNAN_CATTANEO]: 'https://tickets.bnphernancattaneo.com.ar',
};

export const STAGING_URLS_BY_WHITE_LABEL = {
  [WHITE_LABELS.MANDARINE]: 'https://mandarinegroup.net',
  [WHITE_LABELS.VENTI]: 'https://pre.venti.com.ar',
  [WHITE_LABELS.DARK_VENTI]: 'https://pre.venti.com.ar',
};

export const THEME_BY_WHITE_LABEL = {
  [WHITE_LABELS.MANDARINE]: mandarineTheme,
  [WHITE_LABELS.UNDER_CLUB]: underClubTheme,
  [WHITE_LABELS.VENTI]: lightVentiTheme,
  [WHITE_LABELS.DARK_VENTI]: darkVentiTheme,
  [WHITE_LABELS.BNP]: bnpTheme,
  [WHITE_LABELS.HERNAN_CATTANEO]: bnpTheme,
};

export const PRODUCTION_URLS = Object.values(PRODUCTION_URLS_BY_WHITE_LABEL).map((url) =>
  url.slice(8)
);

export const STAGING_URLS = Object.values(STAGING_URLS_BY_WHITE_LABEL).map((url) => url.slice(8));
