import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    headerContainer: {
      backgroundColor: theme.palette.header.background,
      zIndex: 20,
      width: '100%',
      position: 'fixed',
    },
    mainHeaderContainer: {
      padding: '14px 16px',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      maxWidth: '95%',
      alignSelf: 'center',
    },
    subHeaderContainer: {
      boxShadow: '0px 16px 16px -16px #21212140',
      padding: '0 16px 0',
      justifyContent: 'end',
      backgroundColor: theme.palette.header.subHeaderBackground,
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      height: 40,
    },
    logo: {
      height: '100%',
    },
    loginButton: {
      border: `2px solid ${theme.palette.header.buttonText}`,
      background: theme.palette.header.buttonBackground,
      ...theme.typography.button2,
      textTransform: 'initial',
      color: theme.palette.header.buttonText,
      padding: '9px 16px 9px 16px',
      borderRadius: 40,
      textDecoration: 'none',
      height: 40,
      display: 'flex',
      alignItems: 'center',

      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.grey[100],
      },
    },
  });

export default styles;
