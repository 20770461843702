import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ThemeContext } from '../../Providers/Theme/Theme';

class Steps extends React.Component {
  render() {
    const { classes } = this.props;

    function ProcessStep(props) {
      return (
        <div className={classes.processStep}>
          <div className={classes.processTop}>
            <Typography variant="h5" align="left" className={classes.infoTitle}>
              <b>{props.step}</b> {props.title}
            </Typography>
            <img src={props.icon} className={classes.processStepIcon} alt="" />
          </div>
          <Typography variant="body2" align="left" className={classes.infoText}>
            {props.description}
          </Typography>
        </div>
      );
    }

    return (
      <ThemeContext.Consumer>
        {({ icons }) => (
          <div className={classes.info}>
            <div className={classes.infoBox}>
              <ProcessStep
                step="1"
                title="Elegí"
                icon={icons.pencil}
                description="Seleccioná tus accesos y agregalos al Carrito de Compra."
              />
              <div className={classes.arrowBox}>
                <img src={icons.arrow} className={classes.arrow} alt="" />
              </div>
            </div>
            <div className={classes.infoBox}>
              <ProcessStep
                step="2"
                title="Pagá"
                icon={icons.card}
                description="Ingresá los datos de pago de forma 100% segura."
              />
              <div className={classes.arrowBox}>
                <img src={icons.arrow} className={classes.arrow} alt="" />
              </div>
            </div>
            <div className={classes.infoBox}>
              <ProcessStep
                step="3"
                title="Recibí"
                icon={icons.check}
                description="Recibí tus accesos QR en la casilla de correo que informaste."
              />
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}
const styles = (theme) => ({
  arrowBox: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    height: 80,
    marginRight: 20,
    marginLeft: 20,
    transform: 'rotate(180deg)',
    [theme.breakpoints.down('xs')]: {
      transform: 'rotate(-90deg)',
      height: 50,
      margin: 0,
    },
  },
  info: {
    background: theme.palette.grey[200],
    width: 'fit-content',
    padding: 30,
    margin: 'auto',
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: 'auto',
    },
  },
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: 15,
    marginLeft: 15,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: 0,
    },
  },
  processStep: {
    width: 140,
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: 200,
    },
  },
  processStepIcon: {
    width: 22,
    margin: 'auto',
  },
  processTop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoText: {
    marginTop: 20,
    alignItems: 'center',
    // color: '#616266',
    [theme.breakpoints.down('xs')]: {
      marginTop: 2,
    },
  },
});

export default withStyles(styles)(Steps);
