export const USER_ROLES = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  READ_ONLY_ADMIN: 'read-only-admin',
  SELLER_WITH_CASH: 'seller-with-cash',
  SELLER: 'seller',
  CUSTOMER: 'customer',
  VALIDATOR: 'validator',
};

export const PAYMENT_METHODS = {
  LINE: 'line',
  MERCADO_PAGO: 'mercadopago',
  MACRO: 'macro',
};

export const INPUT_TYPE = {
  MAIL: 'mail',
  CONFIRM_MAIL: 'confirmMail',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  ALIAS: 'alias',
  TEXT: 'text',
  NUMERIC: 'numeric',
  DATE: 'date',
  ALPHA_NUMERIC: 'alphaNumeric',
  CREDIT_CARD_NUMBER: 'creditCardNumber',
  ALPHA: 'alpha',
  ID_TYPE: 'idType',
  ID_NUMBER: 'idNumber',
  BIRTH_DATE: 'birthDate',
};

export const TICKET_VISIBILITY_MODE = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

export const TICKET_ACTIVITY_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  INACTIVE: 'inactive',
};

export const TICKET_TYPE = {
  PRESENTIAL: 'presential',
  STREAMING: 'streaming',
  AUTOSHOW: 'autoshow',
  TABLE: 'table',
  INVITATION: 'invitation',
};

export const SECONDARY_MARKET_PUBLICATION_STATUS = {
  PUBLISHED: 'published',
  SOLD: 'sold',
  BOUGHT: 'bought',
  UNPUBLISHED: 'unpublished',
};

export const BACKOFFICE_PRODUCER_CARD_CATEGORY = {
  CONFIG: 'config',
  TEAM: 'team',
  HELP: 'help',
};

export const REQUEST_RESPONSE = {
  SUCCESS: 'ok',
  ERROR: 'error',
};

export const HTTP_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  SERVER_INTERNAL_ERROR: 500,
  SUCCESS: 200,
};

export const DISCOUNT_CODES_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const ENVIRONMENTS = {
  LOCAL: 'local',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const REPORTS_DATA_TYPE = {
  ACCESSES: 'accesses',
  SALES: 'sales',
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const QR_STATUS = {
  PAID: 'paid',
  USED: 'used',
  PENDING: 'pending',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
};

export const NFT_STATUS = {
  UNCLAIMABLE: 'unclaimable',
  CLAIMABLE: 'claimable',
  ASIGNED: 'asigned',
};

export const ORDER_TYPE = {
  ONLINE: 'online',
  CASH: 'cash',
  FREE: 'free',
  INVITATION: 'invitation',
};

export const ORDER_STATUS = {
  PAID: 'paid',
  PENDING: 'pending',
  CANCELED: 'canceled',
  NOT_PAID: 'not_paid',
  REFUNDED_WITHOUT_FEE: 'refunded_without_fee',
  REFUNDED_WITH_FEE: 'refunded_with_fee',
};

export const CURRENCY = {
  ARS: 'ARS',
  USD: 'USD',
};

export const REFUND_TYPE = {
  REFUND_WITHOUT_FEE: 'refundWithoutFee',
  REFUND_WITH_FEE: 'refundWithFee',
};

export const NOTIFICATION_VARIANT = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const USER_PERMISSIONS = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  ADMIN_WITH_READ_ONLY: 'admin-with-read-only',
  BACKOFFICE: 'backoffice',
  SELLERS: 'sellers',
  SELLER_WITH_CASH: 'seller-with-cash',
  LOGGED: 'logged',
};

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
};

export const MATERIAL_UI_ICONS = {
  DEFAULT: 'material-icons',
  OUTLINED: 'material-icons-outlined',
  TWO_TONE: 'material-icons-two-tone',
  ROUND: 'material-icons-round',
  SHARP: 'material-icons-sharp',
};
