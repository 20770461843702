import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Stack } from '@mui/material';
import EditProducerForm from '../../../Components/Forms/EditProducer/EditProducerForm';
import Button from '../../../Venti-UI-Kit/Buttons/Button';

import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { handleRequestHelper } from '../../../utils/helpers';
import history from '../../../appHistory';
import { NOTIFICATION_VARIANT } from '../../../constants/types';
import api from '../../../api/api';

class EditProducer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      producer: {
        name: '',
        theme: '',
      },
      showErrors: false,
      errorStatus: false,
    };
  }

  async componentDidMount() {
    await handleRequestHelper({
      endpoint: () => api.getProducer(this.props.urlParams.producerId),
      onSuccess: ({ producer }) => this.setState({ producer }),
      onFailure: () => history.push('/error/404'),
    });
  }

  handleProducerChange = (producer) => {
    this.setState({ producer });
  };

  async uploadProducer() {
    if (this.state.errorStatus || !this.validateProducer()) {
      this.setState({ showErrors: true });
      this.props.showNotification('Existen errores en los campos', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    await handleRequestHelper({
      endpoint: () => api.updateProducer(this.state.producer),
      showNotification: this.props.showNotification,
      onSuccess: () => {
        this.props.showNotification(
          'Se actualizó la productora! Redirgiendo...',
          NOTIFICATION_VARIANT.SUCCESS
        );
        setTimeout(() => {
          history.push(`/backoffice/productoras/${this.props.urlParams.producerId}`);
        }, 2000);
      },
    });
  }

  validateProducer() {
    const p = this.state.producer;
    const fields = [p.name, p.theme];

    if (fields.some((field) => !field)) {
      this.props.showNotification('Faltan completar campos', NOTIFICATION_VARIANT.ERROR);
      return false;
    }

    return true;
  }

  changeErrorStatus(status) {
    this.setState({ errorStatus: status });
  }

  render() {
    const { classes } = this.props;

    return (
      <PageContainer user={this.props.user} title="Backoffice - Editar Productora">
        <VBreadcrumbs pageTitle="Configuración" />
        <Stack alignItems="center" gap={3}>
          <Typography variant="h3">Editar productora</Typography>

          <EditProducerForm
            producer={this.state.producer}
            handleProducerChange={this.handleProducerChange.bind(this)}
            changeError={this.changeErrorStatus.bind(this)}
            showErrors={this.state.showErrors}
          />

          <Button
            type="submit"
            color="main"
            onClick={this.uploadProducer.bind(this)}
            className={classes.submit}
          >
            Guardar
          </Button>
        </Stack>
      </PageContainer>
    );
  }
}

const styles = (theme) => ({
  buttonDiv: {
    maxWidth: '300px',
  },
  note: {
    margin: 50,
    color: theme.palette.secondary.main,
    fontWeight: 400,
  },
});

export default withStyles(styles)(EditProducer);
