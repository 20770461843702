import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './AutocompleteOption.styles';
import VTypography from '../../../../Venti-UI-Kit/VTypography/VTypography';
import { formatDatetime } from '../../../../utils/utils';
import history from '../../../../appHistory';

const AutocompleteOption = ({ classes, option, ...props }) => {
  const { key, ...optionProps } = props;
  const { label, promoImg, urlName, placeName, startDate } = option;
  const { time, date } = formatDatetime(startDate);
  return (
    <Stack
      {...optionProps}
      flexDirection="row"
      alignItems="start !important"
      gap={1}
      key={key}
      onClick={() => history.push(`/evento/${urlName}`)}
    >
      <img src={promoImg} className={classes.optionImage} alt={key} />
      <Stack height="100%" justifyContent="space-between">
        <VTypography linesNumber={2} variant="subtitle3" color="#121212">
          {label}
        </VTypography>
        <VTypography linesNumber={1} variant="body2" color="#616161">
          {placeName}
        </VTypography>
        <VTypography variant="body2" color="#616161">
          {date} - {time}
        </VTypography>
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(AutocompleteOption);
