import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Stack } from '@mui/material';
import EditEventForm from '../../../Components/Forms/EditEvent/EditEventForm';
import api from '../../../api/api';
import {
  TICKET_ACTIVITY_STATUS,
  REQUEST_RESPONSE,
  NOTIFICATION_VARIANT,
} from '../../../constants/types';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { validateEvent } from './eventValidations';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import Button from '../../../Components/Buttons/Button/Button';
import history from '../../../appHistory';
import { handleRequestHelper } from '../../../utils/helpers';

class NewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      producerStadiums: [],
      event: {
        stadium: null,
        startDate: null,
        endDate: null,
        defaultStopOfSalesDate: null,
        shouldExtendStopOfSalesDateToInvitations: true,
        shouldBlacklistSellers: false,
        hoursBeforeStartForQrsRelease: 12,
      },
      showErrors: false,
      errorStatus: false,
      isLoading: false,
    };
  }

  async componentDidMount() {
    const { producerId, eventId } = this.props.urlParams;

    try {
      await api.getProducer(producerId);
      await this.getProducerStadiums(producerId);
      let newEvent = { ...this.state.event, producerId: Number(producerId) };
      if (eventId) {
        const eventData = await api.getEvent(eventId);
        newEvent = eventData.event ?? newEvent;
        newEvent.urlName = null;
        newEvent.name = null;
      }
      this.setState({
        event: newEvent,
      });
    } catch (err) {
      console.error(err);
      history.push('/error/404');
    }
  }

  async getProducerStadiums(producerId) {
    handleRequestHelper({
      endpoint: () => api.getProducerStadiums(producerId),
      onSuccess: ({ stadiums }) => {
        if (stadiums) this.setState({ producerStadiums: stadiums });
      },
      showNotification: this.props.showNotification,
    });
  }

  handleNewEventChange = (event) => {
    this.setState({ event: { ...event } });
  };

  createEventStadium = async (event, stadium) => {
    if (!stadium) return;
    const { sections } = stadium;
    const sectionsToCreate = sections.map(({ id, ...sectionData }) => ({
      stadiumLayoutSectionId: id,
      ...sectionData,
    }));

    const { status } = await api.createEventStadium(event.id, stadium.id, {
      sections: sectionsToCreate,
    });

    if (status !== REQUEST_RESPONSE.SUCCESS) {
      history.push('/error/eventStadium');
    }
  };

  createEvent = async (event) => {
    try {
      const data = await api.createEvent(event);
      const { eventId } = this.props.urlParams;
      if (eventId) {
        let tickets = this.state.event.TicketTypes;
        tickets = tickets.filter((p) => p.activityStatus !== TICKET_ACTIVITY_STATUS.ARCHIVED);
        for (const _ticket of tickets) {
          const ticket = {
            ..._ticket,
            soldUnits: 0,
            eventId: data.event.id,
          };
          await api.createTicketType(ticket);
        }
      }
      return data;
    } catch (err) {
      this.props.showNotification(
        'Ocurrió un error al crear el evento',
        NOTIFICATION_VARIANT.ERROR
      );
      throw err;
    }
  };

  async uploadEvent() {
    const { producerId } = this.props.urlParams;
    this.setState({ isLoading: true });

    if (this.state.errorStatus) {
      this.setState({ showErrors: true, isLoading: false });
      this.props.showNotification('Existen errores en los campos', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    if (!validateEvent(this.state.event, this.props.showNotification)) {
      this.setState({ isLoading: false });
      return;
    }

    try {
      const { stadium, ...eventData } = this.state.event;
      const { event, status, esmessage } = await this.createEvent(eventData);

      if (status !== REQUEST_RESPONSE.SUCCESS) {
        this.props.showNotification(esmessage, NOTIFICATION_VARIANT.ERROR);
        this.setState({ isLoading: false });
        return;
      }

      await this.createEventStadium(event, stadium);

      this.props.showNotification(
        'Se creó el evento! Redirgiendo...',
        NOTIFICATION_VARIANT.SUCCESS
      );

      setTimeout(() => {
        history.push(`/backoffice/productoras/${producerId}/eventos/${event.id}/tickets/crear`);
      }, 1000);
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  changeErrorStatus(status) {
    this.setState({ errorStatus: status });
  }

  render() {
    const { eventId } = this.props.urlParams;
    const pageAction = eventId ? 'Duplicar' : 'Crear';

    return (
      <PageContainer title={`Backoffice - ${pageAction} Evento`}>
        <VBreadcrumbs pageTitle={pageAction} />
        <Stack alignItems="center" gap={3} px={2}>
          <Typography variant="h3">{`${pageAction} Evento`}</Typography>

          <EditEventForm
            event={this.state.event}
            user={this.props.user}
            handleEventChange={this.handleNewEventChange.bind(this)}
            changeError={this.changeErrorStatus.bind(this)}
            showErrors={this.state.showErrors}
            producerStadiums={this.state.producerStadiums}
          />
          <Button
            fullwidth={false}
            title={pageAction}
            onClick={this.uploadEvent.bind(this)}
            loading={this.state.isLoading}
          />
        </Stack>
      </PageContainer>
    );
  }
}

export default NewEvent;
