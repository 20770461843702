import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import styles from './CustomerProfilePageStyles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';
import Button from '../../../Components/Buttons/Button/Button';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { ARGENTINA_PROVINCES } from '../../../constants/constants';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import { ID_TYPE_OPTIONS } from '../../../constants/identificationTypes';
import { GENDERS } from '../../../constants/selectorOptions';
import Alert from '../../../Venti-UI-Kit/Alert/Alert';
import TextButton from '../../../Components/Buttons/TextButton/TextButton';
import { useCustomerProfilePage } from './CustomerProfilePage.hook';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';

const CustomerProfilePage = ({ classes }) => {
  const { user } = useAuth();
  const { show, formState, handleInputChange, updateUser, isSaveInfoButtonDisabled, isLoading } =
    useCustomerProfilePage();

  return (
    <PageContainer title="Mis Datos">
      <Box className={classes.userDataPage}>
        <Box gap={3} display="flex" flexDirection="column">
          <VTypography variant="h1">Mis Datos</VTypography>
          <Alert
            severity="info"
            message={
              <VTypography variant="body2">
                En caso de querer modificar algún dato adicional, contactate con{' '}
                <TextButton title="nuestro soporte" onClick={show} underline />.
              </VTypography>
            }
          />
          <Box className={classes.formContainer}>
            <TextInput
              title={formState.firstName.title}
              onChange={handleInputChange}
              name="firstName"
              errorMessage={formState.firstName.error}
              defaultValue={formState.firstName.value}
              disabled={!!user.firstName}
            />
            <TextInput
              title={formState.lastName.title}
              onChange={handleInputChange}
              name="lastName"
              errorMessage={formState.lastName.error}
              defaultValue={formState.lastName.value}
              disabled={!!user.lastName}
            />
            <SelectInput
              title={formState.idType.title}
              onChange={handleInputChange}
              name="idType"
              options={ID_TYPE_OPTIONS}
              value={formState.idType.value}
              errorMessage={formState.idType.error}
              defaultValue={formState.idType.value}
              disabled={!!user.identificationType}
            />
            <TextInput
              title={formState.idNumber.title}
              onChange={handleInputChange}
              name="idNumber"
              errorMessage={formState.idNumber.error}
              defaultValue={formState.idNumber.value}
              disabled={!!user.identificationValue}
            />
            <TextInput
              title={formState.phone.title}
              onChange={handleInputChange}
              name="phone"
              errorMessage={formState.phone.error}
              defaultValue={formState.phone.value}
            />
            <TextInput
              title={formState.birthDate.title}
              onChange={handleInputChange}
              name="birthDate"
              type="tel"
              mask="99/99/9999"
              errorMessage={formState.birthDate.error}
              maskChar={'_'}
              defaultValue={formState.birthDate.value}
              disabled={!!user.birthDate}
            />
            <SelectInput
              title={formState.gender.title}
              onChange={handleInputChange}
              name="gender"
              value={formState.gender.value}
              options={GENDERS}
              errorMessage={formState.gender.error}
              defaultValue={formState.gender.value}
            />
            <SelectInput
              title={formState.residenceLocation.title}
              onChange={handleInputChange}
              name="residenceLocation"
              value={formState.residenceLocation.value}
              options={ARGENTINA_PROVINCES.map((residenceLocation) => ({
                label: residenceLocation,
                value: residenceLocation,
              }))}
              errorMessage={formState.residenceLocation.error}
              defaultValue={formState.residenceLocation.value}
              disabled={!!user.residenceLocation}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            onClick={updateUser}
            fullWidth={false}
            title="Guardar"
            disabled={isSaveInfoButtonDisabled}
            width={200}
            loading={isLoading}
          />
        </Box>
      </Box>
    </PageContainer>
  );
};

export default withStyles(styles)(CustomerProfilePage);
