import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
    },
    pageContentContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },
    categoryItem: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: 20,
      width: '100%',
    },
    closedSidebar: {
      minWidth: 0,
      width: 0,
      transition: 'all 0.4s',
      overflow: 'hidden',
    },
    menuButton: {
      marginRight: 'auto',
    },
    noMenu: {
      display: 'none',
    },
    paper: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      marginBottom: 20,
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 50,
      // paddingLeft: '8rem',
      // paddingRight: '8rem',
      // [theme.breakpoints.down('md')]: {
      //   paddingLeft: '0rem',
      //   paddingRight: '0rem',
      // },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    cards: {
      margin: 20,
      display: 'grid',
      gap: '25px 25px',
      gridTemplateColumns: 'repeat(2, 1fr)',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
  });

export default styles;
