import React from 'react';
import { IconButton, useTheme, withStyles } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import styles from './SearcherStyles';
import CustomInput from '../../CustomInput/CustomInput';
import { useFormDeprecated } from '../../../hooks/useFormDeprecated';

const Searcher = ({ classes, onSubmit, placeholder }) => {
  const theme = useTheme();
  const { formState, handleInputChange } = useFormDeprecated({
    searchQuery: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState.searchQuery);
  };

  return (
    <form onSubmit={handleSubmit} className={classes.searcherContainer}>
      <CustomInput
        fullWidth
        placeholder={placeholder}
        name="searchQuery"
        onChange={handleInputChange}
        value={formState.searchQuery}
        onSubmit={handleSubmit}
        endAdornment={
          <IconButton
            type="submit"
            style={{ color: theme.palette.text.secondary }}
            onClick={handleSubmit}
          >
            <SearchOutlined />
          </IconButton>
        }
      />
    </form>
  );
};

export default withStyles(styles)(Searcher);
