import api from '../../../../../api/api';
import { removeStringSpaces } from '../../../../../utils/utils';
import { getCardType, getMonthAndYearFromCardExpirationDate } from '../creditCardUtils';
import { getInstallmentLabel } from '../checkoutUtils';
import { PaymentMethod } from './PaymentMethod';
import { handleRequestHelper } from '../../../../../utils/helpers';
import { LINE_IDENTIFICATION_TYPES } from '../../../../../constants/identificationTypes';
import { encrypt } from './PaymentMethods.utils';

export class LinePaymentMethod extends PaymentMethod {
  async getAvailableCards() {
    await handleRequestHelper({
      endpoint: () => api.getAvailableCards(this.order.event.id),
      showNotification: this.showNotification,
      onSuccess: ({ cardsAvailable }) => {
        this.availableCards = cardsAvailable;
      },
    });
  }

  getIdentificationTypes() {
    return Object.keys(LINE_IDENTIFICATION_TYPES).map((type) => {
      const { label, value } = LINE_IDENTIFICATION_TYPES[type];
      return { label, value };
    });
  }

  async getInstallments(cardNumber) {
    let installmentsOptions = [];
    await handleRequestHelper({
      endpoint: () => api.getCardInstallments(this.order.event.id, cardNumber),
      showNotification: this.showNotification,
      onSuccess: ({ installments }) => {
        installmentsOptions = installments.map(({ installment, interest }) => ({
          label: getInstallmentLabel(installment, interest, this.order.resume.totalToPay),
          value: installment,
        }));
      },
    });
    return installmentsOptions;
  }

  validateCardNumber(cardNumber) {
    let cardNumberError = '';

    const { cardLength, isLemonCard } = getCardType(cardNumber);
    const cardNumberIsComplete = cardNumber.length === cardLength;

    const validIssuer = this.availableCards.some(({ regex }) => {
      const regexValidation = new RegExp(regex, 'g');
      return cardNumber.match(regexValidation);
    });

    if (cardNumberIsComplete) {
      if (!validIssuer) cardNumberError = 'Método de pago no disponible. Intentá con uno diferente';
      if (this.order.isLemonExclusive && !isLemonCard)
        cardNumberError = 'Método de pago inválido. Compra exclusiva con Brubank';
    }

    return { cardNumberIsComplete, cardNumberError };
  }

  async processPayment({ data, onSuccess, onFailure }) {
    const { cardExpirationDate, cardNumber, cvc, cardName, idType, idValue, installments } = data;
    const { month, year } = getMonthAndYearFromCardExpirationDate(cardExpirationDate.value);
    const cardNumberWithoutSpaces = removeStringSpaces(cardNumber.value);

    const paymentData = {
      cardNumber: cardNumberWithoutSpaces,
      expirationDate: `${year}${month}`,
      securityCode: cvc.value,
      name: cardName.value.toUpperCase(),
      idType: idType.value,
      idNumber: idValue.value,
      installments: installments.value ? parseInt(installments.value) : undefined,
    };

    const encryptedPaymentData = encrypt.encrypt(JSON.stringify(paymentData));

    return handleRequestHelper({
      endpoint: () => api.payLineOrder(this.order.id, { paymentData: encryptedPaymentData }),
      onSuccess: () => onSuccess(),
      onFailure: () => onFailure(),
      showNotification: this.showNotification,
    });
  }
}
