import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    modalContainer: {
      borderRadius: 10,
      padding: 25,
      height: '90%',
      width: '90%',
      maxHeight: 'unset',
      maxWidth: 'unset',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      margin: 0,
      overflowY: 'unset',
      [theme.breakpoints.down('xs')]: {
        padding: 10,
      },
    },
    closeButton: {
      position: 'absolute',
      right: 10,
      top: 10,
      zIndex: 15,
      [theme.breakpoints.down('xs')]: {
        right: 0,
      },
    },
    modalTitle: {
      padding: 0,
      paddingLeft: 8,
      marginBottom: 20,
      alignSelf: 'start',
    },
    modalContent: {
      marginTop: 15,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '&:first-child': {
        paddingTop: 0,
      },
    },
  });

export default styles;
