import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Box, IconButton, Stack } from '@mui/material';
import Slider from 'react-slick';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PriceProvider from '../../../Providers/Price/Price';
import { TICKET_VISIBILITY_MODE } from '../../../constants/types';
import TicketQuantitySelector from '../../TicketQuantitySelector/TicketQuantitySelector';
import styles from './EventTicketsCardStyles';
import InformationModal from '../../../Venti-UI-Kit/Modals/InformationModal';
import { formatPrice } from '../../../utils/utils';

const TICKET_TYPE = {
  table: 'Acceso Múltiple',
  presential: 'Acceso Estándar',
  streaming: 'Streaming',
  autoshow: 'Autoshow',
};

const EventTicketsCard = ({ classes, currentValue, ticket, addToCart }) => {
  const {
    type,
    id,
    Images: images,
    description,
    name,
    priceInCents,
    priceInCentsUSD,
    priceInCentsWithLemon,
    groupSize,
    isLemonExclusive,
    TicketTypeCollection,
    canBeSelled,
    visibilityMode,
    soldUnits,
    maxQuantityPerOrder,
    maxQuantity,
    currency,
    shouldConvertPriceFromUSD,
    ticketTypeSection,
  } = ticket;

  const [openDisclaimerModal, setOpenDisclaimerModal] = useState(false);

  const section = ticketTypeSection && ticketTypeSection[0];
  const stock = Math.max(maxQuantity - soldUnits, 0);
  const hasImages = images.length > 0;
  const ticketGroupSize = groupSize || 1;
  const priceInARS = PriceProvider.getPrice(priceInCents, currency);
  const priceInUSD = PriceProvider.getPrice(priceInCentsUSD, currency);
  const priceWithLemon = PriceProvider.getPrice(priceInCentsWithLemon, currency);
  const lemonDiscount = Math.floor(100 - (priceWithLemon * 100) / priceInARS);
  const hasDiscountWithLemon = priceInARS !== priceWithLemon;
  const ticketQuantityToSell = Math.floor(Math.min(stock, maxQuantityPerOrder) / ticketGroupSize);

  const selectTicketQuantity = ({ target }) => {
    const { value } = target;
    addToCart({
      ...ticket,
      quantity: value * ticketGroupSize,
      photoURL: images[0]?.url,
      price: priceInARS,
      priceWithLemon,
    });
  };

  const isAvailableLabel =
    canBeSelled && visibilityMode === TICKET_VISIBILITY_MODE.VISIBLE
      ? 'Entrada disponible'
      : 'Entrada NO disponible';

  const getPriceLabel = (price) => {
    return shouldConvertPriceFromUSD ? formatPrice(price, true, false, 'USD') : formatPrice(price);
  };

  const handleOpenDisclaimerModal = () => {
    setOpenDisclaimerModal(true);
  };

  const handleCloseDisclaimerModal = () => {
    setOpenDisclaimerModal(false);
  };

  const Price = () => {
    const hasSubtitle =
      hasDiscountWithLemon ||
      isLemonExclusive ||
      ticketGroupSize > 1 ||
      TicketTypeCollection.lemonCollectionId;

    const mainPrice = shouldConvertPriceFromUSD ? priceInUSD : priceInARS;
    const secondPriceLabel = shouldConvertPriceFromUSD
      ? formatPrice(priceInARS * ticketGroupSize, true, true)
      : '';
    const mainPriceLabel = getPriceLabel(mainPrice);
    const multipleAccessPriceLabel = getPriceLabel(mainPrice * ticketGroupSize);

    return (
      <>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography className="ticketCardMainPrice">{multipleAccessPriceLabel}</Typography>
          {shouldConvertPriceFromUSD && (
            <IconButton
              className="priceInfoIcon"
              onClick={handleOpenDisclaimerModal}
              color="primary"
            >
              <InfoOutlinedIcon />
            </IconButton>
          )}
        </Stack>

        {secondPriceLabel && (
          <Typography className="ticketCardSecondPrice">{secondPriceLabel}</Typography>
        )}
        {hasSubtitle && (
          <Stack
            className="ticketCardPriceSubtitle"
            direction="column"
            alignItems={{ xs: 'end', md: hasImages ? 'start' : 'end' }}
          >
            {hasDiscountWithLemon && <span>Descuento con Brubank {lemonDiscount}%</span>}
            {isLemonExclusive && <span>Venta exclusiva con Brubank</span>}
            {ticketGroupSize > 1 && (
              <span>
                {groupSize} entradas x {mainPriceLabel} c/u
              </span>
            )}
            {TicketTypeCollection.lemonCollectionId && 'Lemon Drop: Este acceso contiene un NFT'}
          </Stack>
        )}
      </>
    );
  };

  return (
    <div id="ticketCard" className={classes.ticketCardContainer}>
      <Box
        maxWidth={900}
        gap={2}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Stack gap={2} direction={{ xs: 'column', md: hasImages ? 'column' : 'row' }} width="100%">
          <Stack alignItems="start" flex={3}>
            <Stack direction="row" gap={1} alignItems="center">
              {section && (
                <div className="sectionChip" style={{ backgroundColor: section.color }}>
                  {section.name}
                </div>
              )}
              <Typography>{TICKET_TYPE[type]}</Typography>
            </Stack>
            <Typography className="ticketCardTitle">{name}</Typography>
            <Typography className="ticketCardDescription">{description}</Typography>
          </Stack>
          <Stack flex={1} gap={2} alignItems={{ xs: 'end', md: hasImages ? 'start' : 'end' }}>
            <Stack alignItems="inherit" gap={1}>
              <Typography className="ticketCardAvailableLabel">{isAvailableLabel}</Typography>
              <Price />
            </Stack>
            <TicketQuantitySelector
              value={currentValue / ticketGroupSize}
              ticketId={`ticket-selector-${id}`}
              onSelect={selectTicketQuantity}
              canBeSelled={canBeSelled}
              maxQuantity={ticketQuantityToSell}
            />
          </Stack>
        </Stack>
        {hasImages && (
          <Box minWidth={{ xs: 300, md: 350 }} width={{ xs: 300, md: 350 }}>
            <Slider
              dots
              speed={500}
              slidesToScroll={1}
              slidesToShow={1}
              autoplay
              autoplaySpeed={2000}
              appendDots={(dots) => <ul style={{ bottom: -15 }}> {dots} </ul>}
            >
              {images.map(({ url }) => (
                <img className="ticketCardImage" src={url} key={url} alt={url} />
              ))}
            </Slider>
          </Box>
        )}
      </Box>
      <InformationModal
        open={openDisclaimerModal}
        handleClose={handleCloseDisclaimerModal}
        handleAccept={handleCloseDisclaimerModal}
        title="Sobre el valor de la entrada"
        okLabel="Entendido"
        subtitle={
          <>
            El pago de todos los servicios publicados será en pesos Argentinos, conforme Res. 7/2002
            de S. C, D. y D.C.
            <br />
            <br />
            El valor expresado en dólares es solamente de referencia.
          </>
        }
      />
    </div>
  );
};

export default withStyles(styles)(EventTicketsCard);
