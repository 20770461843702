import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const AdminCard = (props) => {
  const { classes, buttons, img, title } = props;

  const Buttons = () => {
    if (!buttons) return null;

    return (
      <div className={classes.buttons}>
        {buttons.map(({ link, text, buttonVariant }) => (
          <Button
            href={link}
            key={text}
            className={buttons.length > 1 && classes.fullWidth}
            color={buttonVariant}
          >
            {text}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <Card elevation={0} className={classes.card}>
      {img && <img src={img} className={classes.logo} alt="Banner" />}
      <div className={classes.texts}>
        <VTypography variant="subtitle2">{title}</VTypography>
      </div>
      <Buttons buttons={buttons} />
    </Card>
  );
};

const styles = (theme) => ({
  infoText: {
    margin: 'auto',
    marginBottom: 10,
    marginTop: 10,
    [theme.breakpoints.up('md')]: {
      width: '90%',
    },
    width: '90%',
    fontSize: '100%',
  },
  cardTitle: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 20,
    justifyContent: 'right',
    minWidth: 400,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      rowGap: 20,
      minWidth: 'unset',
    },
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  divider: {
    marginRight: '3%',
    marginLeft: '3%',
    height: '80px',
    borderLeft: '2px solid',
    color: theme.palette.grey[400],
  },
  logo: {
    display: 'flex',
    objectFit: 'cover',
    boxSizing: 'border-box',
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  card: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    rowGap: 20,
    padding: 20,
    columnGap: 20,
    overflowWrap: 'anywhere',
    justifyContent: 'space-between',
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[200]}`,
    minHeight: 100,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'auto',
    },
    borderRadius: 20,
  },
  fullWidth: {
    width: '100%',
  },
});

export default withStyles(styles)(AdminCard);
