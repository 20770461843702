import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    cloudsContainer: {
      position: 'relative',
      width: '100%',
      bottom: 43,
      height: 240,
      userSelect: 'none',
      pointerEvents: 'none',

      [theme.breakpoints.down('sm')]: {
        bottom: 30,
        height: 200,
      },

      '& .topCloudsContainer': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        height: 150,
        width: '100%',
        bottom: 70,

        [theme.breakpoints.down('sm')]: {
          height: 110,
          bottom: 20,
        },
      },

      '& .bottomCloudsContainer': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        height: 140,
        width: '100%',
        bottom: 60,

        [theme.breakpoints.down('sm')]: {
          height: 130,
          bottom: 40,
        },
      },

      '& .desktopCloud': {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      '& .mobileCloud': {
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },

    '@keyframes breathingAnimation': {
      '0%': {
        transform: 'scale(1) rotate(0)',
      },
      '50%': {
        transform: 'scale(0.87) rotate(-10deg)',
      },
      '100%': {
        transform: 'scale(1) rotate(0)',
      },
    },
  });

export default styles;
