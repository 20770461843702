import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    arc: {
      stroke: theme.palette.primary.main,
    },
  });

export default styles;
