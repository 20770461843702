import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box, Stack, Modal } from '@mui/material';
import styles from './ModalStyles';
import TextInput from '../Inputs/TextInput';
import Button from '../../Components/Buttons/Button/Button';

const FormModal = ({
  classes,
  open,
  okLabel = 'Ok',
  cancelLabel = 'Cancelar',
  title,
  subtitle,
  handleClose,
  onSubmit,
  okDisabled,
  form,
}) => {
  const { formState, handleInputChange } = form;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalContainer}
      disableEscapeKeyDown
    >
      <Box className="modalBoxContainer">
        <Typography className="modalTitle">{title}</Typography>
        <Typography className="modalSubtitle">{subtitle}</Typography>

        {Object.entries(formState).map(([name, { type, title }]) => (
          <TextInput
            key={name}
            title={title}
            errorMessage={formState[type]?.error}
            onChange={handleInputChange}
            type={type}
            name={name}
            value={formState[type]?.value}
          />
        ))}

        <Stack width="100%" gap={1} justifyContent="space-between">
          <Button fullWidth title={cancelLabel} onClick={handleClose} variant="outlined" />
          <Button
            fullWidth
            title={okLabel}
            disabled={okDisabled}
            onClick={onSubmit}
            variant="contained"
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(FormModal);
