const styles = (theme) => ({
  input: {
    marginBottom: 10,
    marginTop: 10,
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    width: '80%',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  title: {
    width: '90%',
    fontWeight: '500',
    padding: 10,
    fontSize: '120%',
  },
  button: {
    marginTop: 20,
    marginBottom: 30,
  },
  nameCol: {
    margin: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  paper: {
    borderRadius: 8,
    margin: 0,
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  paperImg: {
    position: 'relative',
    borderRadius: '20px',
    backgroundColor: theme.palette.grey[50],
    width: '100%',
    display: 'flex',
    margin: '10px auto',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down('md')]: {
      padding: 2,
    },
  },
  bannerContainer: {
    width: '100%',
    height: '200px',
    justifyContent: 'center',
  },
  status: {
    border: 'solid 2px',
    borderRadius: '20px',
    borderColor: theme.palette.grey[200],
  },
  iconContainer: {
    marginLeft: 'auto',
    color: theme.palette.secondary.main,
    width: '2em',
    height: '2em',
  },
  ImgIcon: {
    display: 'flex',
    alignItems: 'left',
    width: '1em',
    height: '1em',
  },
  iconTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  infoGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  imgDropContainer: {
    maxWidth: 150,
    marginBottom: 40,
  },
  infoItem: {
    flexDirection: 'column',
    width: '48%',
    padding: 10,
    [theme.breakpoints.down('xs')]: {
      width: '98%',
      flexDirection: 'column',
    },
  },
  themeSelectionContainer: {
    margin: '0 auto',
  },
});

export default styles;
