import { createStyles } from '@material-ui/core';

const styles = () => {
  const logoStyles = {
    height: 24,
    margin: '12px 0',
    width: 'auto',
  };
  return createStyles({
    container: {
      backgroundColor: 'red',
    },
    outlinedLogo: {
      ...logoStyles,
      '& path': {
        fill: 'transparent !important',
        stroke: '#121212',
        strokeWidth: 2,
      },
    },

    filledLogo: {
      ...logoStyles,
      '& path': {
        fill: '#121212 !important',
      },
    },
  });
};

export default styles;
