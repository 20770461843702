import { ORDER_STATUS } from '../../../constants/types';

export const updateStatusesParam = (statuses) => {
  const params = new URLSearchParams(window.location.search);
  params.delete('statuses');

  if (statuses.length > 0) {
    params.append('statuses', JSON.stringify(statuses));
  }

  window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
};

export const getStatusesParam = () => {
  const params = new URLSearchParams(window.location.search);
  const statusesParams = params.get('statuses');

  const initialParams = Object.values(ORDER_STATUS).filter(
    (status) => status !== ORDER_STATUS.NOT_PAID
  );

  return statusesParams ? JSON.parse(statusesParams) : initialParams;
};
