import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Stack } from '@mui/material';
import TitledInput from '../../../Venti-UI-Kit/Input/TitledInput';
import { getTextHelperText } from '../../../Venti-UI-Kit/Input/Errors';
import { icons } from '../../../Providers/Icons/IconsProvider';
import styles from './EditProducerForm.styles';
import { getRadioHelperText } from '../../../Venti-UI-Kit/RadioButtons/Errors';
import TitledRadioButtons from '../../../Venti-UI-Kit/RadioButtons/TitledRadioButtons';

import ventiTheme from '../../../assets/themes/venti.png';
import darkVentiTheme from '../../../assets/themes/ventidark.png';
import { WHITE_LABELS } from '../../../constants/whiteLabels';

class EditProducerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        name: false,
        theme: false,
      },
    };
  }

  handleInputChange = (event) => {
    const { value, name } = event.target;
    let inputValue = value;
    const newProducer = this.props.producer;
    if (value === '') inputValue = null;
    newProducer[name] = inputValue;
    this.props.handleProducerChange(newProducer);
  };

  changeErrorCurrentStatus(input, error, errorFields) {
    errorFields = Object.keys(errorFields);

    if (this.state.errors[input] !== error) {
      let showErrorsState = error;

      if (errorFields.includes(input)) {
        if (errorFields.length > 1) showErrorsState = true;
      }

      this.setState({ errors: { ...this.state.errors, [input]: error } });
      this.props.changeError(showErrorsState);
    }
  }

  checkInputError(value, input, getHelperText) {
    const helperText = getHelperText(value);
    const error = helperText !== '';

    const errorState = this.state.errors;
    const errorFields = Object.keys(errorState).reduce((arr, key) => {
      if (errorState[key]) arr[key] = errorState[key];
      return arr;
    }, {});

    this.changeErrorCurrentStatus(input, error, errorFields);

    return helperText;
  }

  render() {
    const { classes } = this.props;
    // const templateMails = [
    //   { value: 'venti.html', name: 'Venti' },
    //   { value: 'emailTemplate.html', name: 'Databoy' },
    //   { value: 'ticketTemplate.html', name: 'Lemon Tickets' },
    //   { value: 'casino.html', name: 'Casino' }
    // ];

    const themes = [
      // { value: 'lemon', src: lemonTheme , name: 'Lemon'},
      { value: WHITE_LABELS.VENTI, src: ventiTheme, name: 'Light' },
      { value: WHITE_LABELS.DARK_VENTI, src: darkVentiTheme, name: 'Dark' },
    ];

    return (
      <Stack component="form" gap={2} width="100%" maxWidth={900} onSubmit={this.onSubmit}>
        <Paper elevation={0} className={classes.paper}>
          <TitledInput
            title="Nombre de la productora"
            name="name"
            autocomplete="off"
            value={this.props.producer.name}
            icon={icons.pencil}
            onChange={this.handleInputChange.bind(this)}
            helperText={this.checkInputError(this.props.producer.name, 'name', getTextHelperText)}
            showErrors={this.props.showErrors}
          />
        </Paper>

        <Paper elevation={0} className={classes.paper}>
          <TitledRadioButtons
            title="Apariencia"
            name="theme"
            icon={icons.image}
            currentValue={this.props.producer.theme}
            onChange={this.handleInputChange.bind(this)}
            values={themes}
            helperText={this.checkInputError(
              this.props.producer.theme,
              'theme',
              getRadioHelperText
            )}
            showErrors={this.props.showErrors}
            withImages
            row
          />
        </Paper>
      </Stack>
    );
  }
}

export default withStyles(styles)(EditProducerForm);
