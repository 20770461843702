import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './VentiBlueTapeStyles';
import Marquee from '../../Animations/Marquee/Marquee';
import { ReactComponent as VentiLogo } from '../../../assets/header/headerVentiLogo.svg';

const VentiBlueTape = ({ classes }) => {
  return (
    <Marquee
      animationTime="35s"
      backgroundColor="#5758F5"
      gap={16}
      rightToLeft
      contentRepetiations={16}
      height={48}
    >
      <VentiLogo className={classes.outlinedLogo} />
      <VentiLogo className={classes.filledLogo} />
    </Marquee>
  );
};

export default withStyles(styles)(VentiBlueTape);
