import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import esLocale from 'date-fns/locale/es';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import RenderInput from './RenderInput';
import { icons } from '../../Providers/Icons/IconsProvider';

const CustomDatePicker = ({
  minDate = new Date(),
  maxDate,
  value = '',
  onChange,
  helperText,
  showErrors,
  clearable = false,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <DatePicker
        label="Fecha"
        name="date"
        maxDate={maxDate}
        minDate={minDate}
        value={value}
        onChange={onChange}
        icon={icons.date}
        format="d 'de' MMMM"
        TextFieldComponent={RenderInput}
        okLabel="ok"
        cancelLabel="Cancelar"
        clearable={clearable}
        clearLabel="Eliminar"
        helperText={helperText}
        showErrors={showErrors}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;
