import { useEffect, useState } from 'react';
import { parse } from 'date-fns';
import { useIntercom } from 'react-use-intercom';
import { INPUT_TYPE, NOTIFICATION_VARIANT } from '../../../constants/types';
import { handleRequestHelper } from '../../../utils/helpers';
import { useForm } from '../../../hooks/useForm';
import { formatDatetime, getUrlSearchParams } from '../../../utils/utils';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import api from '../../../api/api';
import history from '../../../appHistory';

export const useCustomerProfilePage = () => {
  const { showNotification } = useNotifications();
  const { returnTo } = getUrlSearchParams();
  const { user } = useAuth();
  const { show } = useIntercom();

  const [isSaveInfoButtonDisabled, setIsSaveInfoButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const birthDate = user.birthDate ? formatDatetime(user.birthDate).date : undefined;

  const { formValues, formState, isFormComplete, handleInputChange, validateFormInputs } = useForm({
    firstName: {
      title: 'Nombre',
      type: INPUT_TYPE.ALPHA,
      required: false,
      value: user.firstName,
    },
    lastName: {
      title: 'Apellido',
      type: INPUT_TYPE.ALPHA,
      required: false,
      value: user.lastName,
    },
    idType: {
      title: 'Tipo de identificación',
      type: INPUT_TYPE.ID_TYPE,
      required: false,
      value: user.identificationType,
    },
    idNumber: {
      title: 'Número de identificación',
      type: INPUT_TYPE.ID_NUMBER,
      required: false,
      value: user.identificationValue,
    },
    phone: {
      title: 'Número de teléfono',
      type: INPUT_TYPE.NUMERIC,
      required: false,
      value: user.phone,
    },
    residenceLocation: {
      title: 'Lugar de residencia',
      type: INPUT_TYPE.TEXT,
      required: false,
      value: user.residenceLocation,
    },
    birthDate: {
      title: 'Fecha de nacimiento (DD/MM/AAAA)',
      type: INPUT_TYPE.BIRTH_DATE,
      required: false,
      value: birthDate,
    },
    gender: {
      title: 'Género',
      type: INPUT_TYPE.TEXT,
      required: false,
      value: user.gender,
    },
  });

  const updateUser = async () => {
    setIsLoading(true);
    setIsSaveInfoButtonDisabled(true);

    const isFormValid = validateFormInputs();

    if (!isFormValid) {
      setIsSaveInfoButtonDisabled(false);
      showNotification('Complete correctamente los campos', NOTIFICATION_VARIANT.ERROR);
      setIsLoading(false);
      return;
    }

    const body = {
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      identificationType: formState.idType.value,
      identificationValue: formState.idNumber.value,
      phone: formState.phone.value,
      residenceLocation: formState.residenceLocation.value,
      birthDate: parse(formState.birthDate.value, 'dd/MM/yyyy', new Date()),
      gender: formState.gender.value,
    };

    await handleRequestHelper({
      endpoint: () => api.updateCustomer(body),
      showNotification,
      onSuccess: () => {
        showNotification('Tus datos fueron guardados con éxito', NOTIFICATION_VARIANT.SUCCESS);
        if (returnTo) history.push(returnTo);
        else
          setTimeout(() => {
            window.location.reload();
          }, 500);
      },
    });
    setIsLoading(false);
    setIsSaveInfoButtonDisabled(false);
  };

  useEffect(() => setIsSaveInfoButtonDisabled(!isFormComplete()), [formValues]);

  return {
    show,
    formState,
    handleInputChange,
    updateUser,
    isSaveInfoButtonDisabled,
    isLoading,
  };
};
