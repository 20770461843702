const validateStadium = (eventToCreate) => {
  const { stadium } = eventToCreate;
  if (!stadium) return;

  let totalSectionsCapacity = 0;
  for (const { name, capacity } of stadium.sections) {
    totalSectionsCapacity += capacity;
    if (!name) return 'Todos los sectores del estadio tienen que tener nombre';
    if (!capacity) return 'Todos los sectores del estadio tienen que tener una capacidad definida';
  }

  if (totalSectionsCapacity > stadium.capacity)
    return 'Los sectores no pueden tener una mayor capacidad que la totalidad del estadio';
};

export const validateEvent = (event, showNotification) => {
  const { name, status, placeName, startDate, endDate, defaultStopOfSalesDate } = event;
  const fields = [name, status, placeName, startDate, endDate, defaultStopOfSalesDate];

  if (fields.some((field) => !field)) {
    showNotification('Faltan completar campos', 'error');
    return false;
  }

  if (new Date(defaultStopOfSalesDate) < new Date()) {
    showNotification(
      'La fecha y hora de finalización de venta de tickets debe ser posterior a la actual',
      'error'
    );
    return false;
  }

  const errorMessage = validateStadium(event);
  if (errorMessage) {
    showNotification(errorMessage, 'error');
    return false;
  }

  return true;
};
