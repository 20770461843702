import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import styles from './SmallEventCardStyles';
import VentiTexture from '../../../../../Components/Assets/VentiTexture/VentiTexture';
import { formatDatetime } from '../../../../../utils/utils';

const SmallEventCard = ({ classes, event, userAlias }) => {
  const { name, promoImg, location, startDate, placeName, urlName } = event;
  const { dayOfWeek, date, time } = formatDatetime(startDate);

  const eventLocation = location ? `${location.city} (${location.shortName})` : placeName;
  const eventUrl = userAlias ? `/evento/${urlName}/${userAlias}` : `/evento/${urlName}`;

  return (
    <a className={`${classes.cardContainer} animate__animated animate__fadeInUp`} href={eventUrl}>
      <div className="image" style={{ backgroundImage: `url(${promoImg})` }} alt="banner" />
      <div className="cardInfoContainer ">
        <div className="nameContainer">
          <Typography className="name">{name}</Typography>
        </div>
        <div className="dateContainer">
          <Typography className="dateTimeText">{dayOfWeek}</Typography>
          <Typography className="dateTimeText">{date}</Typography>
          <Typography className="dateTimeText">{time}hs</Typography>
        </div>
        <div className="locationContainer">
          <FmdGoodOutlinedIcon style={{ height: 14 }} />
          <Typography className="locationText">{eventLocation}</Typography>
        </div>
        <div className="assetContainer">
          <VentiTexture animationTime="0s" />
        </div>
      </div>
    </a>
  );
};

export default withStyles(styles)(SmallEventCard);
