/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Sidebar.styles';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const SM_BREAKPOINT = 768;

const Sidebar = ({ classes, title, items, handleSelectItem }) => {
  const [selectedItem, setSelectedItem] = useState(items[0].key);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [menuClassName, setMenuClassName] = useState();
  const [mobile, setMobile] = useState();
  const { width } = useWindowDimensions();

  const handleToggle = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleSelect = (itemKey) => {
    setSelectedItem(itemKey);
    handleSelectItem(itemKey);
    mobile && handleToggle();
  };

  useEffect(() => {
    mobile && setOpenSidebar(false);
  }, [mobile]);

  useEffect(() => {
    if (width <= SM_BREAKPOINT) {
      setMobile(true);
      setMenuClassName(
        `${classes.sidebarContentContainer} ${
          openSidebar ? classes.transitionToActive : classes.transitionToInactive
        }`
      );
    } else {
      setOpenSidebar(true);
      setMobile(false);
      setMenuClassName(`${classes.sidebarContentContainer} ${classes.transitionToActive}`);
    }
  }, [width, openSidebar]);

  return (
    <div className={`${classes.container} ${mobile && !openSidebar && classes.closeContainer}`}>
      <div className={classes.sidebarContainer}>
        <button
          type="button"
          onClick={handleToggle}
          className={`${classes.icon} ${classes.menuIcon} ${
            openSidebar && classes.transitionToInactive
          }`}
        >
          <MenuIcon />
        </button>
        <div className={menuClassName}>
          <button
            type="button"
            onClick={handleToggle}
            className={`${classes.icon} ${classes.closeIcon}`}
          >
            <CloseIcon />
          </button>
          <Typography className={classes.title}>{title}</Typography>
          <div className={classes.listContainer}>
            {items.map(({ title, key, icon }, index) => (
              <button
                type="button"
                key={index}
                className={`${classes.listItemContainer} ${
                  selectedItem === key && classes.selected
                }`}
                onClick={() => handleSelect(key)}
              >
                {icon}
                <Typography className={classes.itemText}>{title}</Typography>
              </button>
            ))}
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={handleToggle}
        className={`${mobile && openSidebar ? classes.sidebarBackground : classes.hidden}`}
      />
    </div>
  );
};

export default withStyles(styles)(Sidebar);
