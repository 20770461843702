import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { motion } from 'framer-motion/dist/framer-motion.dev';
import styles from './ArcSvgStyles';

const MAX_EXTENSION_RADIUS = 5;
const MAX_ROTATION = 5;
const INSIDE_OR_LEFT_DIRECTION = -1;
const OUTSIDE_OR_RIGHT_DIRECTION = 1;

const ArcSvg = ({
  classes,
  cx,
  cy,
  r = 10,
  startAngle = 0,
  endAngle = 180,
  strokeWidth = 3,
  rotation = 0,
  fill = '#121212',
  stroke,
}) => {
  const [randomRadius, setRandomRadius] = useState(r);
  const [randomRotation, setRandomRotation] = useState(rotation);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const direction = Math.random() > 0.5 ? OUTSIDE_OR_RIGHT_DIRECTION : INSIDE_OR_LEFT_DIRECTION;
      setRandomRadius(r + Math.random() * direction * MAX_EXTENSION_RADIUS);
      setRandomRotation(rotation + Math.random() * direction * MAX_ROTATION);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [r, rotation]);

  const polarToCartesian = (angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: cx + randomRadius * Math.cos(angleInRadians),
      y: cy + randomRadius * Math.sin(angleInRadians),
    };
  };

  const startCoords = polarToCartesian(endAngle);
  const endCoords = polarToCartesian(startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  const pathData = `M ${startCoords.x} ${startCoords.y} A ${randomRadius} ${randomRadius} 0 ${largeArcFlag} 0 ${endCoords.x} ${endCoords.y}`;

  const pathStyle = {
    stroke,
    originX: `${cx}px`,
    originY: `${cy}px`,
  };

  const transition = {
    duration: 1.1,
    ease: 'linear',
    repeat: Infinity,
  };

  return (
    <>
      <motion.path
        className={classes.arc}
        fill={fill}
        strokeWidth={strokeWidth}
        transition={transition}
        style={pathStyle}
        animate={{
          d: pathData,
          transform: `rotate(-${randomRotation + 270}deg) scaleX(-1)`,
        }}
      />
      <motion.circle
        cx={cx}
        cy={cy}
        fill={fill}
        strokeWidth={0}
        transition={transition}
        animate={{
          r: randomRadius,
        }}
      />
    </>
  );
};

export default withStyles(styles)(ArcSvg);
