import React from 'react';
import { withStyles } from '@material-ui/core';
import Cards from 'react-credit-cards';
import styles from './CreditCardStyles';
import 'react-credit-cards/es/styles-compiled.css';

const CreditCard = ({ classes, cvc, expiry, focused, name, number }) => {
  return (
    <div className={classes.creditCardContainer}>
      <Cards
        cvc={cvc}
        expiry={expiry}
        focused={focused}
        name={name}
        locale={{ valid: 'Fecha de expiración' }}
        number={number}
        placeholders={{ name: 'Nombre en la tarjeta' }}
      />
    </div>
  );
};

export default withStyles(styles)(CreditCard);
