import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 40,
    },
    producersContainer: {
      alignSelf: 'center',
      rowGap: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '55%',
      [theme.breakpoints.down('sm')]: {
        width: '75%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: 0,
      },
    },
  });

export default styles;
