import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box, Modal, Stack } from '@mui/material';
import styles from './ModalStyles';
import Button from '../../Components/Buttons/Button/Button';

const ConfirmModal = ({
  classes,
  open,
  acceptLabel = 'Aceptar',
  cancelLabel = 'Cancelar',
  loading = false,
  title,
  subtitle,
  handleAccept,
  handleClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalContainer}
      disableEscapeKeyDown
    >
      <Box className="modalBoxContainer">
        <Typography className="modalTitle">{title}</Typography>
        <Typography className="modalSubtitle">{subtitle}</Typography>

        <Stack direction="column" spacing={2}>
          <Button
            fullWidth
            disabled={loading}
            title={cancelLabel}
            onClick={handleClose}
            variant="outlined"
          />
          <Button
            fullWidth
            loading={loading}
            title={acceptLabel}
            onClick={handleAccept}
            variant="contained"
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(ConfirmModal);
