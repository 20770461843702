import React, { useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Box, Stack } from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { withRouter } from 'react-router-dom';
import styles from './OrderCard.styles';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';
import IconButton from '../Buttons/IconButton/IconButton';
import { formatDatetime, formatPrice } from '../../utils/utils';
import { translate } from '../../utils/translate';
import { getOrderColorsByStatus } from './OrderCard.utils';
import ResendOrderBottomSheet from '../BottomSheets/ResendOrderBottomSheet/ResendOrderBottomSheet';
import ChangeOrderMailBottomSheet from '../BottomSheets/ChangeOrderMailBottomSheet/ChangeOrderMailBottomSheet';
import { ORDER_STATUS } from '../../constants/types';
import history from '../../appHistory';

const OrderCard = ({ classes, order }) => {
  const theme = useTheme();

  const { mail, firstName, lastName, identificationType, identificationValue } = order.owner;
  const { color, backgroundColor, border } = getOrderColorsByStatus(order.status, theme);
  const [currentOwnerMail, setCurrentOwnerMail] = useState(mail);

  const goToOrder = () => {
    history.push(`${window.location.pathname}/${order.id}`);
  };

  const [openResendOrderBottomSheet, setOpenResendOrderBottomSheet] = useState(false);
  const [openChangeOrderMailBottomSheet, setOpenChangeOrderMailBottomSheet] = useState(false);

  return (
    <Stack
      className={classes.orderCardContainer}
      style={{ border }}
      direction={{ xs: 'column', md: 'row' }}
      gap={{ xs: 1, md: 5 }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
    >
      <Stack
        className={classes.orderCardItemContainer}
        direction={{ xs: 'row', md: 'column' }}
        justifyContent={{ xs: 'space-between', md: 'flex-start' }}
      >
        <Box>
          <VTypography variant="subtitle3">Orden: #{order.id}</VTypography>
          <VTypography variant="caption">
            {formatDatetime(order.createdAt).fullTimeDate}
          </VTypography>
        </Box>
        <VTypography
          style={{ color, backgroundColor }}
          className={classes.statusChip}
          variant="overline"
        >
          {translate(order.status)}
        </VTypography>
      </Stack>
      <Box className={classes.orderCardItemContainer}>
        <VTypography variant="subtitle5">
          {firstName} {lastName}
        </VTypography>
        <VTypography variant="caption">{currentOwnerMail}</VTypography>
        <VTypography variant="caption">
          {identificationType} - {identificationValue}
        </VTypography>
      </Box>
      <Stack
        direction="row"
        width={{ xs: '100%', md: 'unset' }}
        flex={2}
        gap={5}
        alignItems="center"
      >
        <Box className={classes.orderCardItemContainer}>
          <VTypography className={classes.totalPriceChip} variant="button1">
            {formatPrice(order.totalPrice, false, true)}
          </VTypography>
        </Box>
        <Stack className={classes.orderCardItemContainer} direction="row" gap={1}>
          <IconButton
            Icon={<EditIcon />}
            onClick={() => setOpenChangeOrderMailBottomSheet(true)}
            disabled={order.status !== ORDER_STATUS.PAID}
          />
          <IconButton
            Icon={<ForwardToInboxIcon />}
            onClick={() => setOpenResendOrderBottomSheet(true)}
            disabled={order.status !== ORDER_STATUS.PAID}
          />
          <IconButton Icon={<MoreVertIcon />} onClick={goToOrder} />
        </Stack>
      </Stack>
      <ResendOrderBottomSheet
        open={openResendOrderBottomSheet}
        orderId={order.id}
        onClose={() => setOpenResendOrderBottomSheet(false)}
        qrMails={order.qrMails}
      />
      <ChangeOrderMailBottomSheet
        open={openChangeOrderMailBottomSheet}
        onClose={() => setOpenChangeOrderMailBottomSheet(false)}
        ownerMail={currentOwnerMail}
        orderId={order.id}
        setNewMail={setCurrentOwnerMail}
      />
    </Stack>
  );
};

export default withRouter(withStyles(styles)(OrderCard));
