import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    pageContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
    },
    titleAndCartContainer: {
      borderRadius: '20px',
      backgroundColor: theme.palette.background.default,
      width: '100%',
      display: 'flex',
      margin: 'auto',
      marginBottom: 20,
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 16px',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 10,
      },
    },
    imageAndDescriptionContainer: {
      display: 'flex',
      justifyContent: 'start',
      maxWidth: 840,
      width: '100%',
      margin: '24px auto 0',
      gap: 16,

      [theme.breakpoints.down('md')]: {
        padding: '0 16px',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },

      '& .descriptionImage': {
        aspectRatio: 4 / 5,
        height: 400,
        width: 320,
      },

      '& .descriptionContainer': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: 8,
        width: '100%',

        [theme.breakpoints.down('sm')]: {
          alignItems: 'center',
        },
      },

      '& .descriptionTitle': {
        fontSize: 32,
        fontWeight: 600,
        lineHeight: '24px',
      },

      '& .description': {
        width: '100%',
        fontSize: 16,
        fontWeight: 400,
        textAlign: 'left',
      },

      '& p': {
        margin: 0,
      },
    },
    eventDesciptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
    title: {
      fontWeight: 600,
      color: theme.palette.text.primary,
      fontSize: '3rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
    info: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        alignItems: 'center',
      },
    },
    infoCol: {
      width: '10rem',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '.5rem',
        width: 'auto',
        flexDirection: 'column',
        height: '4rem',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      },
    },
    infoColShare: {
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '.5rem',
        width: '100%',
        justifyContent: 'center',
      },
    },
    infoColLocation: {
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '.5rem',
        width: 'auto',
        flexDirection: 'column',
        height: '4rem',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        flexDirection: 'column',
        height: '4rem',
        alignItems: 'center',
        justifyContent: 'space-evenly',
      },
    },
    infoTitle: {
      color: theme.palette.text.color,
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {},
    },
    infoText: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '30px',

      [theme.breakpoints.down('sm')]: {
        marginBottom: 5,
        width: '100%',
        alignItems: 'center',
        height: 'auto',
      },
    },
    locationTextIcon: {
      color: 'aaa',
      marginRight: 10,
      marginLeft: 20,
      marginBottom: 20,
      height: 35,
    },
    infoTextText: {
      color: theme.palette.text.color,
      fontSize: '.9rem',
      textAlign: 'left',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    infoTextLocation: {
      color: theme.palette.text.color,
      fontSize: '1rem',
      textAlign: 'center',
      justifyContent: 'center',
    },
    footerIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 50,
    },
    footerIcon: {
      height: 80,
      filter: 'drop-shadow( -3px 3px 5px rgba(0, 0, 0, .2))',
    },
    foto: {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    artista: {
      display: 'flex',
      width: '80%',
      padding: '0',
      margin: '0',
      columnGap: 40,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
        width: '100%',
      },
    },
    text: {
      textAlign: 'left',
      marginTop: '1em',
      padding: '0 2.5em',
      color: theme.palette.text.color,
      lineHeight: '1.5em',
    },
    buttonIcon: {
      height: 40,
      width: 40,
      padding: 5,
      marginTop: 3,
    },
    notFoundSellerText: {
      fontSize: 24,
      fontWeight: 500,
      width: 600,
      alignSelf: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 17,
        width: '50%',
      },
    },
    notFoundSellerLink: {
      color: theme.palette.primary.main,
    },
    activityIndicator: {
      color: theme.palette.primary.main,
    },
    eventTicketsList: {
      '& #ticketCard:nth-child(even)': {
        backgroundColor: theme.palette.grey[100],
      },
      '& #ticketCard:nth-child(odd)': {
        backgroundColor: theme.palette.grey[50],
      },
    },
    stadiumImage: {
      width: 400,
      borderRadius: 8,
      marginBottom: 16,

      [theme.breakpoints.down('md')]: {
        width: 328,
      },
    },
  });

export default styles;
