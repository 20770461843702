import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    carouselContent: {
      width: '95%',
      height: '100%',

      '& .slick-slider': {
        height: '100%',
      },

      '& .slick-list': {
        height: '100%',
      },

      '& .slick-track': {
        display: 'flex',
        height: '100%',
      },

      '& .slick-slide': {
        transform: 'scale(0.9)',
        zIndex: 1,
        opacity: 0.5,
        transition: 'all 300ms ease',

        '& > div': {
          height: '100%',
        },
      },

      '& .slick-center': {
        opacity: 1,
        transform: 'scale(1)',
        zIndex: 2,
      },

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  });

export default styles;
