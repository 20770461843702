import React, { useEffect, useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { parse } from 'date-fns';
import { Box, Stack } from '@mui/material';
import styles from './SearchEventsResultsPage.styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { getUrlSearchParams } from '../../../utils/utils';
import MediumEventCard from '../Home/Components/MediumEventCard/MediumEventCard';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';

const SearchEventsResultsPage = ({ classes }) => {
  const { from, until, query } = getUrlSearchParams();
  const theme = useTheme();
  const [events, setEvents] = useState([]);

  const parsedFrom = parse(from, 'dd/MM/yyyy', new Date());
  const parsedUntil = parse(until, 'dd/MM/yyyy', new Date());
  const formatFromDate = from && new Date(parsedFrom.setHours(-3, 0, 0, 0)).toISOString();
  const formatUntilDate = until && new Date(parsedUntil.setHours(20, 59, 0, 0)).toISOString();

  const getEvents = async () => {
    await handleRequestHelper({
      endpoint: () =>
        api.getHomeEvents({
          searchQuery: query,
          from: formatFromDate,
          until: formatUntilDate,
        }),
      onSuccess: ({ events }) => setEvents(events),
    });
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <PageContainer showSearchButton withFooter>
      <Stack className={classes.pageContainer}>
        <Stack width="100%">
          <VTypography variant="h3">Resultados</VTypography>
          {query && (
            <VTypography variant="subtitle6" color={theme.palette.grey[700]}>
              Busqueda: {query}
            </VTypography>
          )}
          {(from || until) && (
            <VTypography variant="subtitle6" color={theme.palette.grey[700]}>
              {from} - {until}
            </VTypography>
          )}
        </Stack>
        <Box className={classes.eventsContainer}>
          {events.length === 0 ? (
            <VTypography variant="h6" color={theme.palette.grey[400]}>
              No se encontraron eventos.
            </VTypography>
          ) : (
            events?.map((event) => <MediumEventCard event={event} key={event.id} />)
          )}
        </Box>
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(SearchEventsResultsPage);
