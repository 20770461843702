import React from 'react';
import { Stack } from '@mui/material';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import OrderCard from '../../../Components/OrderCard/OrderCard';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Searcher from '../../../Components/Backoffice/Searcher/Searcher';
import LoadingContainer from '../../../Components/LoadingContainer/LoadingContainer';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import { useEventOrdersPage } from './EventOrdersPage.hook';

const EventOrdersPage = ({ urlParams }) => {
  const {
    selectedOptions,
    orders,
    orderStatusFilterOptions,
    isGettingMoreItems,
    setSearchQuery,
    onChange,
  } = useEventOrdersPage(urlParams.eventId);

  return (
    <PageContainer title="Backoffice - Lista de órdenes">
      <VBreadcrumbs />
      <Stack alignItems="center" padding={2}>
        <Stack gap={2} alignItems="center" maxWidth={900} width="100%">
          <VTypography variant="h3">Lista de órdenes</VTypography>
          <Stack width="100%" gap={2}>
            <Searcher
              onSubmit={(searchValue) => setSearchQuery(searchValue)}
              placeholder="Buscar por id, dni o mail"
            />
            <SelectInput
              width={{ xs: '100%', sm: 300 }}
              title="Estado de la orden"
              value={selectedOptions}
              multiple
              options={orderStatusFilterOptions}
              onChange={onChange}
            />
          </Stack>
          <LoadingContainer isLoading={!orders && isGettingMoreItems}>
            {orders.map((order) => (
              <OrderCard order={order} key={order.id} />
            ))}
          </LoadingContainer>
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default EventOrdersPage;
