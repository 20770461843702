import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import LoginPage from '../../Pages/Public/LoginPage/LoginPage';
import SignupPage from '../../Pages/Public/SignupPage/SignupPage';
import UserEvents from '../../Pages/Public/UserEvents/UserEvents';
import CustomRoute from './CustomRoute';
import VentiHome from '../../Pages/Public/Home/VentiHome';
import WhiteLabelHome from '../../Pages/Public/Home/WhiteLabelHome';
import ProducerList from '../../Pages/Backoffice/Producers/ProducerList/ProducerList';
import Event from '../../Pages/Public/Event/Event/Event';
import UserEventTickets from '../../Pages/Public/UserEventTickets/UserEventTickets';
import EventOrdersPage from '../../Pages/Backoffice/EventOrdersPage/EventOrdersPage';
import AdminProducerPage from '../../Pages/Backoffice/Info/AdminProducerPage/AdminProducerPage';
import InfoEvent from '../../Pages/Backoffice/Info/InfoEvent/InfoEvent';
import ChangePasswordPage from '../../Pages/Public/ChangePasswordPage/ChangePasswordPage';
import ErrorMailValidationPage from '../../Pages/Public/MailValidationPages/ErrorMailValidationPage';
import MailValidationSentPage from '../../Pages/Public/MailValidationPages/MailValidationSentPage';
import SuccessMailValidationPage from '../../Pages/Public/MailValidationPages/SuccessMailValidationPage';
import NewProducer from '../../Pages/Backoffice/Producers/NewProducer';
import EditProducer from '../../Pages/Backoffice/Producers/EditProducer';
import ManageTicketTypes from '../../Pages/Backoffice/TicketTypes/ManageTicketTypes';
import NewTicketType from '../../Pages/Backoffice/TicketTypes/NewTicketType';
import EditTicketType from '../../Pages/Backoffice/TicketTypes/EditTicketType';
import NewEvent from '../../Pages/Backoffice/Events/NewEvent';
import EditEvent from '../../Pages/Backoffice/Events/EditEvent';
import EventReports from '../../Pages/Backoffice/EventReports/EventReports';
import NotFound404 from '../../Pages/Public/RedirectURL/NotFound404';
import Unauthorized401 from '../../Pages/Public/RedirectURL/Unauthorized401';
import EventStadiumError from '../../Pages/Public/RedirectURL/EventStadiumError';
import CaptchaError from '../../Pages/Public/RedirectURL/CaptchaError';
import PurchaseResultPage from '../../Pages/Public/PurchaseResultPages/PurchaseResultPage';
import UserPanel from '../../Pages/Backoffice/Users/UserPanel/UserPanel';
import CreateUser from '../../Pages/Backoffice/CreateUser/CreateUser';
import EditUser from '../../Pages/Backoffice/EditUser/EditUser';
import CashSale from '../../Pages/Backoffice/Misc/CashSale/CashSale';
import AdminBlacklist from '../../Pages/Backoffice/Events/AdminBlacklist';
import SendInvitationPage from '../../Pages/Backoffice/TicketTypes/SendInvitationPage';
import AdminInvitations from '../../Pages/Backoffice/Invitations/AdminInvitations';
import SendMultipleInvitationsPage from '../../Pages/Backoffice/TicketTypes/SendMultipleInvitationsPage';
import OrderPage from '../../Pages/Backoffice/OrderPage/OrderPage';
import NewTeamLeader from '../../Pages/Backoffice/TeamLeaders/NewTeamLeader/NewTeamLeader';
import TeamLeaderPanel from '../../Pages/Backoffice/TeamLeaders/TeamLeaderPanel/TeamLeaderPanel';
import EditTeamLeader from '../../Pages/Backoffice/TeamLeaders/EditTeamLeader/EditTeamLeader';
import ClaimNFTPage from '../../Pages/Public/NFT/ClaimNFTPage/ClaimNFTPage';
import ResetPassword from '../../Pages/Backoffice/Users/ResetPassword/ResetPassword';
import Checkout from '../../Pages/Public/Event/Checkout/Checkout';

import { USER_PERMISSIONS } from '../../constants/types';
import CustomerProfilePage from '../../Pages/Public/CustomerProfilePage/CustomerProfilePage';
import SearchEventsResultsPage from '../../Pages/Public/SearchEventsResultsPage/SearchEventsResultsPage';

const Routes = ({ setTheme }) => {
  return (
    <Switch>
      <CustomRoute key="claimNFT" path="/claim/:externalId" component={ClaimNFTPage} />,
      <CustomRoute key="loginPage" path="/login" component={LoginPage} />
      <CustomRoute key="signupPage" path="/signup" component={SignupPage} />
      <CustomRoute key="changePasswordPage" path="/changepassword" component={ChangePasswordPage} />
      <CustomRoute key="resetPassword" path="/nueva-contrasena" component={ResetPassword} />
      <CustomRoute key="searchEvents" path="/eventos/search" component={SearchEventsResultsPage} />
      <CustomRoute key="ventiHome" path="/eventos" component={VentiHome} />
      <CustomRoute
        key="errorValidationPage"
        path="/mailvalidation/error"
        component={ErrorMailValidationPage}
      />
      <CustomRoute
        key="validationMailSentPage"
        path="/mailvalidation/sent"
        component={MailValidationSentPage}
      />
      <CustomRoute
        key="successValidationPage"
        path="/mailvalidation/:token"
        component={SuccessMailValidationPage}
      />
      <CustomRoute
        key="CustomerProfilePage"
        path="/mis-datos"
        component={CustomerProfilePage}
        rolePermission={USER_PERMISSIONS.LOGGED}
      />
      <CustomRoute
        key="userEventTickets"
        path="/mis-tickets/:eventId"
        component={UserEventTickets}
        rolePermission={USER_PERMISSIONS.LOGGED}
      />
      <CustomRoute
        key="userEvents"
        path="/mis-tickets"
        component={UserEvents}
        rolePermission={USER_PERMISSIONS.LOGGED}
      />
      <CustomRoute
        key="whiteLabelHome"
        path="/productora/eventos/:producerId/:userAlias?"
        component={WhiteLabelHome}
        setTheme={setTheme}
      />
      <CustomRoute
        key="checkout"
        path="/evento/:urlName/checkout/:externalId"
        component={Checkout}
      />
      <CustomRoute
        key="purchaseResultPage"
        path="/evento/:urlName/resultado-operacion"
        component={PurchaseResultPage}
      />
      <CustomRoute
        key="event"
        path="/evento/:urlName/:seller?"
        component={Event}
        setTheme={setTheme}
      />
      <CustomRoute
        key="newProducer"
        path="/backoffice/productoras/crear"
        component={NewProducer}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="createTicket"
        path="/backoffice/productoras/:producerId/eventos/:eventId/tickets/crear"
        component={NewTicketType}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="editTicket"
        path="/backoffice/productoras/:producerId/eventos/:eventId/tickets/:ticketId"
        component={EditTicketType}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="cashSale"
        path="/backoffice/productoras/:producerId/eventos/:eventId/ventaCash"
        component={CashSale}
        rolePermission={USER_PERMISSIONS.SELLER_WITH_CASH}
      />
      <CustomRoute
        key="eventBlacklist"
        path="/backoffice/productoras/:producerId/eventos/:eventId/blacklist"
        component={AdminBlacklist}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="ticketsList"
        path="/backoffice/productoras/:producerId/eventos/:eventId/tickets"
        component={ManageTicketTypes}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="eventReports"
        path="/backoffice/productoras/:producerId/eventos/:eventId/reportes"
        component={EventReports}
        rolePermission={USER_PERMISSIONS.BACKOFFICE}
      />
      <CustomRoute
        key="invitationsAssignation"
        path="/backoffice/productoras/:producerId/eventos/:eventId/asignarInvitaciones"
        component={AdminInvitations}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="sendInvitation"
        path="/backoffice/productoras/:producerId/eventos/:eventId/enviarInvitaciones"
        component={SendInvitationPage}
        rolePermission={USER_PERMISSIONS.SELLERS}
      />
      <CustomRoute
        key="sendMultipleInvitations"
        path="/backoffice/productoras/:producerId/eventos/:eventId/enviarMultiplesInvitaciones"
        component={SendMultipleInvitationsPage}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="OrderPage"
        path="/backoffice/productoras/:producerId/eventos/:eventId/ordenes/:orderId"
        component={OrderPage}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="eventOrdersPage"
        path="/backoffice/productoras/:producerId/eventos/:eventId/ordenes"
        component={EventOrdersPage}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="duplicateEvent"
        path="/backoffice/productoras/:producerId/eventos/:eventId/duplicarEvento"
        component={NewEvent}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="createEventUser"
        path="/backoffice/productoras/:producerId/eventos/:eventId/crearUsuario"
        component={CreateUser}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="editEvent"
        path="/backoffice/productoras/:producerId/eventos/:eventId/editar"
        component={EditEvent}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="createEvent"
        path="/backoffice/productoras/:producerId/eventos/crear"
        component={NewEvent}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="infoEvent"
        path="/backoffice/productoras/:producerId/eventos/:eventId"
        component={InfoEvent}
        rolePermission={USER_PERMISSIONS.BACKOFFICE}
      />
      <CustomRoute
        key="createTeamLeader"
        path="/backoffice/productoras/:producerId/teamLeaders/crear"
        component={NewTeamLeader}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="editTeamLeader"
        path="/backoffice/productoras/:producerId/teamLeaders/:teamLeaderId"
        component={EditTeamLeader}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="teamLeadersList"
        path="/backoffice/productoras/:producerId/teamLeaders"
        component={TeamLeaderPanel}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="createProducerUser"
        path="/backoffice/productoras/:producerId/usuarios/crear"
        component={CreateUser}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="editProducerUser"
        path="/backoffice/productoras/:producerId/usuarios/:userId"
        component={EditUser}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="producerUsersList"
        path="/backoffice/productoras/:producerId/usuarios"
        component={UserPanel}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="editProducer"
        path="/backoffice/productoras/:producerId/configuracion"
        component={EditProducer}
        rolePermission={USER_PERMISSIONS.ADMIN}
      />
      <CustomRoute
        key="adminProducerPage"
        path="/backoffice/productoras/:producerId"
        component={AdminProducerPage}
        rolePermission={USER_PERMISSIONS.BACKOFFICE}
      />
      <CustomRoute
        key="producersList"
        path="/backoffice/productoras"
        component={ProducerList}
        rolePermission={USER_PERMISSIONS.BACKOFFICE}
      />
      <CustomRoute key="error404" path="/error/404" component={NotFound404} />
      <CustomRoute key="error401" path="/error/401" component={Unauthorized401} />
      <CustomRoute key="errorCaptcha" path="/error/captcha" component={CaptchaError} />
      <CustomRoute
        key="errorEventStadium"
        path="/error/eventStadium"
        component={EventStadiumError}
      />
      <Redirect key="redirectionDefault" to="/eventos" />
    </Switch>
  );
};

export default Routes;
