import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import { Divider } from '@mui/material';
import styles from './ConfirmationStepStyles';
import api from '../../../api/api';
import CartStorage from '../../../Providers/CartStorage/CartStorage';
import priceProvider from '../../../Providers/Price/Price';
import { PAYMENT_METHODS, NOTIFICATION_VARIANT, CURRENCY } from '../../../constants/types';
import { OUT_OF_ARGENTINA_OPTION } from '../../../constants/constants';
import { formatPrice } from '../../../utils/utils';
import CustomCircularProgress from '../../CustomCircularProgress/CustomCircularProgress';
import PaymentMethodSelector from '../../PaymentMethodSelector/PaymentMethodSelector';
import Button from '../../Buttons/Button/Button';
import { IDENTIFICATION_TYPES_VALIDATIONS } from '../../../constants/identificationTypes';
import { useReCaptcha } from '../../../hooks/useReCaptcha';
import GoogleReCaptcha from '../../GoogleReCaptcha/GoogleReCaptcha';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import history from '../../../appHistory';

const ConfirmationStep = ({ classes, cart, order, handleBack }) => {
  const { showNotification } = useNotifications();
  const { user, event, prices, seller, paymentMethods: paymentOptions } = order;
  const { firstName, lastName, idType, idNumber, mail, phoneNumber, province } = user;

  const isPromotedByLemon = () => {
    return cart.reduce(
      (isPromotedByLemon, { priceInCentsWithLemon, priceInCents, isLemonExclusive, currency }) => {
        const priceWithLemon = priceProvider.getPrice(priceInCentsWithLemon, currency);
        const price = priceProvider.getPrice(priceInCents, currency);
        if (priceWithLemon < price || isLemonExclusive) isPromotedByLemon = true;
        return isPromotedByLemon;
      },
      false
    );
  };

  const paymentMethods = !isPromotedByLemon()
    ? paymentOptions
    : paymentOptions.filter((paymentMethod) => paymentMethod !== PAYMENT_METHODS.MACRO);

  const [macroFormData, setMacroFormData] = useState();
  const [macroActionUrl, setMacroActionUrl] = useState('');
  const [processingOrder, setProcessingOrder] = useState(false);
  const isInternational = province === OUT_OF_ARGENTINA_OPTION;
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(paymentMethods[0]);
  const hasSecondCurrency = cart.some(({ shouldConvertPriceFromUSD }) => shouldConvertPriceFromUSD);
  const { buttonActionDisabled, validateRecaptchaToken } = useReCaptcha({
    page: 'createOrder',
    action: 'ticket_buy',
  });

  const isFreeOrder = prices.totalOrderPrice === 0;
  const hasMoreThanOneMethod = paymentMethods.length > 1;

  const onChangePaymentMethod = (method) => {
    setPaymentMethodSelected(method);
  };

  const getQrsFromTickets = () => {
    let qrs = [];
    cart.forEach((ticket) => {
      qrs = [...qrs, ...Array(ticket.quantity).fill({ ticketTypeId: ticket.id })];
    });
    return qrs;
  };

  const getDiscountCode = () => {
    const ticket = cart.find(({ discountCode }) => discountCode?.code);
    return ticket?.discountCode.code;
  };

  const createFreeOrder = async (orderData) => {
    await handleRequestHelper({
      endpoint: () => api.createFreeOrder(orderData),
      onSuccess: ({ link }) => {
        CartStorage.deleteCart();
        window.open(link, '_self');
      },
    });
  };

  const createMercadoPagoOrder = async (orderData) => {
    const order = await handleRequestHelper({
      endpoint: () => api.createMercadoPagoOrder(orderData),
      showNotification,
      onFailure: () => {
        showNotification('Hubo un error al procesar tu orden', NOTIFICATION_VARIANT.ERROR);
        setProcessingOrder(false);
      },
      onSuccess: ({ order, paymentCredentials }) => {
        CartStorage.setStoredMercadoPagoKey(paymentCredentials);

        if (isPromotedByLemon()) {
          CartStorage.deleteCart();
          history.push(`/evento/${event.urlName}/checkout/${order.externalId}`);
        }
        return order;
      },
    });

    await handleRequestHelper({
      endpoint: () => api.getMercadoPagoPaymentLink(order.id),
      showNotification,
      onFailure: (error) => {
        showNotification(
          `Hubo un error al generar link de pago: ${error}`,
          NOTIFICATION_VARIANT.ERROR
        );
        setProcessingOrder(false);
      },
      onSuccess: ({ link }) => {
        CartStorage.deleteCart();
        window.open(link, '_self');
      },
    });
  };

  const createLineOrder = async (orderData) => {
    await handleRequestHelper({
      endpoint: () => api.createLinePaymentOrder(orderData),
      showNotification,
      onSuccess: ({ order }) => {
        CartStorage.deleteCart();
        history.push(`/evento/${event.urlName}/checkout/${order.externalId}`);
      },
      onFailure: () => setProcessingOrder(false),
    });
  };

  const createMacroOrder = async (orderData) => {
    await handleRequestHelper({
      endpoint: () => api.createMacroPaymentOrder(orderData),
      onSuccess: ({ transactionInfo }) => {
        const { url, ...macroData } = transactionInfo;
        setMacroActionUrl(url);
        setMacroFormData(macroData);
        CartStorage.deleteCart();
      },
      onFailure: () => setProcessingOrder(false),
      showNotification,
    });
  };

  const createOrder = async () => {
    const isCaptchaValid = await validateRecaptchaToken();
    if (!isCaptchaValid) return;

    const orderData = {
      title: event.name,
      bannerImg: event.bannerImg,
      hostname: window.location.hostname,
      name: `${firstName} - ${lastName}`,
      phone: phoneNumber,
      dni: `${idType} - ${idNumber}`,
      mail,
      qrs: getQrsFromTickets(),
      eventId: event.id,
      customerId: null,
      sellerAlias: seller,
      province,
      code: getDiscountCode(),
      currency: CURRENCY.ARS,
    };

    if (isFreeOrder) await createFreeOrder(orderData);
    else if (paymentMethodSelected === PAYMENT_METHODS.MERCADO_PAGO)
      await createMercadoPagoOrder(orderData);
    else if (paymentMethodSelected === PAYMENT_METHODS.LINE) await createLineOrder(orderData);
    else if (paymentMethodSelected === PAYMENT_METHODS.MACRO) await createMacroOrder(orderData);
  };

  const onSubmit = async () => {
    setProcessingOrder(true);
    await createOrder();
  };

  useEffect(() => {
    if (macroFormData) {
      const form = document.getElementById('macroForm');
      form.submit();
    }
  }, [macroFormData]);

  return (
    <div className={classes.confirmationStepContainer}>
      {macroFormData && (
        <form id="macroForm" hidden method="post" action={macroActionUrl}>
          {Object.entries(macroFormData).map(([name, value]) => (
            <input key={name} readOnly name={name} value={value} />
          ))}
        </form>
      )}
      {processingOrder ? (
        <div className="confirmingActivityIndicatorContainer">
          <Typography>Procesando tu orden...</Typography>
          <CustomCircularProgress />
        </div>
      ) : (
        <>
          <div className="confirmationStepContent">
            <Typography className="confirmationStepTitle">
              ¡Ya casi es tuyo! Revisa tus datos
            </Typography>
            <div className="confirmationStepResumeContainer">
              <div className="resumeSection">
                <Typography>
                  <strong>Nombre y apellido: </strong> {`${firstName} ${lastName}`}
                </Typography>
                <Typography>
                  <strong>Identificación: </strong>
                  {`${IDENTIFICATION_TYPES_VALIDATIONS[idType].label} - ${idNumber}`}
                </Typography>
                <Typography>
                  <strong>Mail: </strong> {mail}
                </Typography>
                <Typography>
                  <strong>Número de teléfono: </strong> {phoneNumber}
                </Typography>
              </div>

              <Divider className="divider" />

              <div className="resumeSection">
                <Typography>
                  <strong>Nombre del evento: </strong> {event.name}
                </Typography>

                <Typography>
                  <strong>Tus entradas: </strong>
                </Typography>

                <div className="confirmationStepTicketsContainer">
                  {cart.map((ticket) => (
                    <Typography key={ticket.name}>
                      {ticket.quantity} <small>x</small> {ticket.name}
                    </Typography>
                  ))}
                </div>

                {prices?.lemonDiscount > 0 && (
                  <Typography className="confirmationStepLemonDiscountLabel">
                    {prices.lemonDiscount}% Off pagando con Brubank
                  </Typography>
                )}

                {prices?.isLemonExclusive && (
                  <Typography className="confirmationStepLemonDiscountLabel">
                    Venta exclusiva con Brubank
                  </Typography>
                )}

                <Typography>
                  <strong>Costo total de entradas: </strong>
                  {formatPrice(prices.totalTicketsPrice, true, hasSecondCurrency)}
                </Typography>
                <Typography>
                  <strong>Costo de servicio: </strong>
                  {formatPrice(prices.servicePrice, true, hasSecondCurrency)}
                </Typography>

                <Divider className="divider" />

                <Typography>
                  <strong>Total a pagar: </strong>
                  {formatPrice(prices.totalOrderPrice, true, hasSecondCurrency)}
                </Typography>
              </div>

              {!isFreeOrder && hasMoreThanOneMethod && (
                <>
                  <Typography>
                    <strong>Elegí el medio de pago</strong>
                  </Typography>
                  <PaymentMethodSelector
                    paymentMethods={paymentMethods}
                    onChange={onChangePaymentMethod}
                    value={paymentMethodSelected}
                  />
                </>
              )}
            </div>
          </div>
          {isInternational && (
            <div className={classes.disclaimerText}>
              <InfoIcon className={classes.disclaimerIcon} />
              <Typography>
                <strong>IMPORTANTE:</strong> Si vas a realizar un pago con una tarjeta emitida fuera
                de Argentina, recordá ingresar un mail que no se encuentre registrado en Mercado
                Pago en el siguiente paso.
              </Typography>
            </div>
          )}
          <GoogleReCaptcha mt={2} validation={validateRecaptchaToken} />
          <div className={classes.termsButtonsContainer}>
            <Typography className={classes.terms}>
              Al hacer la orden estas aceptando nuestros{' '}
              <a
                className={classes.terms}
                target="_blank"
                rel="noreferrer"
                href="https://legals.venti.live/documentacion/terminosycondiciones"
              >
                términos y condiciones
              </a>
            </Typography>
            <div className={classes.buttonsContainer}>
              <Button onClick={handleBack} variant="outlined" title="Anterior" />
              <Button onClick={onSubmit} title="Confirmar" disabled={buttonActionDisabled} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(ConfirmationStep);
