import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { Box, Modal } from '@mui/material';
import styles from './ModalStyles';
import Button from '../../Components/Buttons/Button/Button';

const InformationModal = ({
  classes,
  open,
  okLabel = 'Ok',
  title,
  subtitle,
  handleClose,
  handleAccept,
  disabled,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalContainer}
      disableEscapeKeyDown
    >
      <Box className="modalBoxContainer">
        <Typography className="modalTitle">{title}</Typography>
        <Typography className="modalSubtitle" component="div">
          {subtitle}
        </Typography>

        <Button
          fullWidth
          title={okLabel}
          onClick={handleAccept}
          disabled={disabled}
          variant="contained"
        />
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(InformationModal);
