import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import styles from './AdminProducerPage.styles';
import EventList from '../../../../Components/Lists/EventList/EventList';
import { BACKOFFICE_PRODUCER_CARD_CATEGORY, USER_PERMISSIONS } from '../../../../constants/types';
import Sidebar from '../../Sidebar/Sidebar';
import CardsList from '../../../../Components/Lists/CardsList/CardsList';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';
import history from '../../../../appHistory';
import { validateUserRole } from '../../../../utils/utils';
import { handleRequestHelper } from '../../../../utils/helpers';
import api from '../../../../api/api';

const SIDEBAR_CATEGORIES = {
  ACTIVE_EVENTS: 'active-events',
  PAST_EVENTS: 'past-events',
  MANAGE_TEAM: 'manage-team',
  CONFIGURATION: 'configuration',
  HELP: 'help',
};

const AdminProducerPage = ({ classes, urlParams }) => {
  const { user } = useAuth();
  const { producerId } = urlParams;
  const [producer, setProducer] = useState();
  const [selectedCategory, setSelectedCategory] = useState(SIDEBAR_CATEGORIES.ACTIVE_EVENTS);

  const sidebarCategoriesList = [
    {
      key: SIDEBAR_CATEGORIES.ACTIVE_EVENTS,
      title: 'Eventos activos',
      icon: <EventIcon />,
      component: <EventList producer={producer} />,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.BACKOFFICE),
    },
    {
      key: SIDEBAR_CATEGORIES.PAST_EVENTS,
      title: 'Eventos pasados',
      icon: <EventIcon />,
      component: <EventList past producer={producer} />,
      permissions: validateUserRole(user.role, USER_PERMISSIONS.BACKOFFICE),
    },
    {
      key: SIDEBAR_CATEGORIES.MANAGE_TEAM,
      title: 'Gestionar equipo',
      icon: <GroupIcon />,
      component: (
        <CardsList
          producerId={producerId}
          generatesSellerReports={producer?.generatesSellerReports}
          category={BACKOFFICE_PRODUCER_CARD_CATEGORY.TEAM}
        />
      ),
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      key: SIDEBAR_CATEGORIES.CONFIGURATION,
      title: 'Configuración',
      icon: <SettingsIcon />,
      component: (
        <CardsList producerId={producerId} category={BACKOFFICE_PRODUCER_CARD_CATEGORY.CONFIG} />
      ),
      permissions: validateUserRole(user.role, USER_PERMISSIONS.ADMIN),
    },
    {
      key: SIDEBAR_CATEGORIES.HELP,
      title: 'Centro de ayuda',
      icon: <HelpIcon />,
      component: (
        <CardsList producerId={producerId} category={BACKOFFICE_PRODUCER_CARD_CATEGORY.HELP} />
      ),
      permissions: validateUserRole(user.role, USER_PERMISSIONS.BACKOFFICE),
    },
  ];

  const getProducer = async () => {
    await handleRequestHelper({
      endpoint: () => api.getProducer(producerId),
      onSuccess: ({ producer }) => setProducer(producer),
      onFailure: () => history.push('/error/404'),
    });
  };

  useEffect(() => {
    getProducer();
  }, []);

  const selectCategory = (category) => {
    setSelectedCategory(category);
  };

  return (
    <PageContainer title={`Backoffice - ${producer?.name}`}>
      <div className={classes.pageContentContainer}>
        <Sidebar
          title={producer?.name}
          items={sidebarCategoriesList.filter(({ permissions }) => permissions)}
          handleSelectItem={selectCategory}
        />
        {producer &&
          sidebarCategoriesList
            .filter(({ permissions, key }) => permissions && key === selectedCategory)
            .map(({ key, component, title }) => (
              <div key={key} className={classes.categoryItem}>
                <Typography variant="h3">{title}</Typography>
                {component}
              </div>
            ))}
      </div>
    </PageContainer>
  );
};

export default withStyles(styles)(AdminProducerPage);
