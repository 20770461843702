import React from 'react';
import Slider from 'react-slick';
import { withStyles } from '@material-ui/core';
import styles from './CarouselStyles';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ classes, children }) => {
  const { width } = useWindowDimensions();
  const minDesktopSize = 1200;

  return (
    <div className={classes.carouselContent}>
      <Slider
        infinite
        speed={500}
        variableWidth
        arrows={width > minDesktopSize}
        slidesToShow={3}
        centerMode
        centerPadding={0}
        autoplay
        autoplaySpeed={5000}
      >
        {children}
      </Slider>
    </div>
  );
};

export default withStyles(styles)(Carousel);
