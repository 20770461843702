import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './CloudsStyles';
import ArcSvg from '../../ArcSvg/ArcSvg';

const Clouds = ({ classes }) => {
  const desktopCloudProps = {
    fill: 'none',
    className: 'desktopCloud',
  };

  const mobileCloudProps = {
    fill: 'none',
    className: 'mobileCloud',
  };

  return (
    <div className={classes.cloudsContainer}>
      <div className="topCloudsContainer">
        {/* left top clouds */}
        <svg width="465" height="150" viewBox="0 70 465 150" {...desktopCloudProps}>
          <ArcSvg cx={141.158} cy={182.662} r={47.9538} endAngle={150} rotation={10} />
          <ArcSvg cx={207.592} cy={214.457} r={47.9538} endAngle={90} rotation={30} />
          <ArcSvg cx={284.775} cy={218.706} r={54.7328} endAngle={120} rotation={30} />
          <ArcSvg cx={368.243} cy={228.685} r={54.7328} endAngle={130} rotation={15} />
          <ArcSvg cx={42.8005} cy={168.304} r={80.7456} endAngle={200} rotation={-10} />
        </svg>

        {/* right top clouds */}
        <svg width="456" height="150" viewBox="0 70 456 150" {...desktopCloudProps}>
          <ArcSvg cx={313.888} cy={182.662} r={47.9538} endAngle={150} rotation={25} />
          <ArcSvg cx={247.454} cy={214.457} r={47.9538} endAngle={100} rotation={55} />
          <ArcSvg cx={170.271} cy={218.706} r={54.7328} endAngle={130} rotation={25} />
          <ArcSvg cx={86.8027} cy={228.685} r={54.7328} endAngle={120} rotation={35} />
          <ArcSvg cx={412.245} cy={168.304} r={80.7456} endAngle={200} rotation={-7} />
        </svg>

        {/* left top clouds */}
        <svg width="120" height="100" viewBox="0 0 120 100" {...mobileCloudProps}>
          <ArcSvg cx={17.6937} cy={43.8552} r={30} endAngle={150} rotation={0} />
          <ArcSvg cx={59.2552} cy={63.746} r={30} endAngle={165} rotation={-20} />
          <ArcSvg cx={79.2552} cy={83.746} r={17} endAngle={145} rotation={0} />
        </svg>

        {/* middle top clouds */}
        <svg width="150" height="142" viewBox="0 0 150 142" {...mobileCloudProps}>
          <ArcSvg cx={99.2552} cy={107} r={25} endAngle={145} rotation={0} />
          <ArcSvg cx={36.8606} cy={100} r={25} endAngle={160} rotation={10} />
          <ArcSvg cx={68.861} cy={108.9641} r={18} endAngle={120} rotation={30} />
        </svg>

        {/* right top clouds */}
        <svg width="97" height="100" viewBox="0 0 97 100" {...mobileCloudProps}>
          <ArcSvg cx={81.0266} cy={43.8552} r={30} endAngle={130} rotation={60} />
          <ArcSvg cx={39.465} cy={63.746} r={30} endAngle={155} rotation={50} />
          <ArcSvg cx={15.2552} cy={83.746} r={17} endAngle={145} rotation={30} />
        </svg>
      </div>

      <div className="bottomCloudsContainer">
        {/* left left bottom clouds */}
        <svg width="391" height="140" viewBox="0 90 391 140" {...desktopCloudProps}>
          <ArcSvg cx={125.968} cy={183.967} r={75.9272} endAngle={150} rotation={0} />
          <ArcSvg cx={233.091} cy={225.836} r={75.9272} endAngle={140} rotation={15} />
          <ArcSvg cx={320.471} cy={225.686} r={47.9538} endAngle={120} rotation={35} />
        </svg>

        {/* left middle bottom clouds */}
        <svg width="308" height="140" viewBox="0 30 308 140" {...desktopCloudProps}>
          <ArcSvg cx={109.333} cy={149.309} r={61.9331} endAngle={120} rotation={25} />
          <ArcSvg cx={198.615} cy={157.649} r={61.9331} endAngle={130} rotation={15} />
        </svg>

        {/* right middle bottom clouds */}
        <svg width="263" height="160" viewBox="0 -20 263 160" {...desktopCloudProps}>
          <ArcSvg cx={175.897} cy={124.649} r={61.9331} endAngle={120} rotation={35} />
          <ArcSvg cx={86.615} cy={132.989} r={61.9331} endAngle={130} rotation={35} />
        </svg>

        {/* right right bottom clouds */}
        <svg width="391" height="160" viewBox="40 20 391 160" {...desktopCloudProps}>
          <ArcSvg cx={279.495} cy={159.134} r={75.9272} endAngle={150} rotation={30} />
          <ArcSvg cx={172.372} cy={201.003} r={75.9272} endAngle={130} rotation={25} />
          <ArcSvg cx={84.9917} cy={200.853} r={47.9538} endAngle={130} rotation={20} />
        </svg>

        {/* left bottom clouds */}
        <svg width="186" height="130" viewBox="0 43 186 130" {...mobileCloudProps}>
          <ArcSvg cx={29.2264} cy={116.28} r={47.5} endAngle={180} rotation={0} />
          <ArcSvg cx={96.7415} cy={126.87} r={47.5} endAngle={150} rotation={0} />
          <ArcSvg cx={138.86} cy={135.89} r={30} endAngle={140} rotation={20} />
          <ArcSvg cx={70.8601} cy={168.89} r={30} endAngle={140} rotation={20} />
        </svg>

        {/* right bottom clouds */}
        <svg width="184" height="130" viewBox="0 43 184 130" {...mobileCloudProps}>
          <ArcSvg cx={157.317} cy={116.28} r={47.5} endAngle={155} rotation={50} />
          <ArcSvg cx={89.8019} cy={126.871} r={47.5} endAngle={150} rotation={30} />
          <ArcSvg cx={115.683} cy={168.89} r={30} endAngle={140} rotation={20} />
          <ArcSvg cx={47.6833} cy={135.89} r={30} endAngle={140} rotation={20} />
        </svg>
      </div>
    </div>
  );
};

export default withStyles(styles)(Clouds);
