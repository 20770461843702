import { createStyles } from '@material-ui/core';

const BREAKPOINT = 'sm';

const styles = (theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      columnGap: 30,
      margin: '20px 30px',

      [theme.breakpoints.down(BREAKPOINT)]: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 20,
      },
    },
    banner: {
      borderRadius: 20,
      width: '60%',
      objectFit: 'cover',
      boxShadow: '1px 1px 20px 1px rgba(0,0,0,0.5)',

      [theme.breakpoints.down(BREAKPOINT)]: {
        width: '100%',
        maxWidth: 600,
        borderRadius: 10,
      },
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
    },
    chipsContainer: {
      display: 'flex',
      width: '80%',
      flexWrap: 'wrap',
      columnGap: 10,
      rowGap: 10,

      [theme.breakpoints.down(BREAKPOINT)]: {
        justifyContent: 'center',
        margin: 'auto',
      },
    },
    chip: {
      background: theme.palette.background.alternative,
      borderRadius: 8,
      width: 'fit-content',
      padding: '10px 15px',
      minHeight: 35,
      display: 'flex',
      columnGap: 7,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'left',

      [theme.breakpoints.down(BREAKPOINT)]: {
        alignSelf: 'center',
      },
    },
    title: {
      fontSize: 32,
      fontWeight: 600,

      [theme.breakpoints.down(BREAKPOINT)]: {
        textAlign: 'center',
        fontSize: 24,
      },
    },
    eventPromotionsContainer: {
      display: 'flex',
      justifyContent: 'start',
      marginTop: 32,
      flex: 1,

      [theme.breakpoints.down(BREAKPOINT)]: {
        justifyContent: 'center',
      },
    },
  });

export default styles;
