import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import AdminCard from '../../../../Components/Cards/AdministrateCard/AdminCard';
import api from '../../../../api/api';
import permissions from '../../../../Providers/Permissions/Permissions';
import { getBaseUrlByTheme } from '../../../../utils/utils';
import Searcher from '../../../../Components/Backoffice/Searcher/Searcher';
import styles from './ProducerListStyles';
import LoadingListContent from '../../../../Components/LoadingListContent/LoadingListContent';
import PageContainer from '../../../../Components/PageContainer/PageContainer';
import { useAuth } from '../../../../Providers/AuthProvider/AuthProvider';
import { useNotifications } from '../../../../Providers/NotificationsProvider/NotificationsProvider';
import { handleRequestHelper } from '../../../../utils/helpers';

const ProducerList = ({ classes }) => {
  const { showNotification } = useNotifications();
  const [producers, setProducers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  const getProducers = async (searchQuery) => {
    setLoading(true);
    handleRequestHelper({
      endpoint: () => api.getAllProducers({ page: currentPage, searchQuery }),
      showNotification,
      onSuccess: ({ producers, totalPages }) => {
        setLoading(false);
        setProducers(producers);
        setTotalPages(totalPages);
      },
      onFailure: () => setLoading(false),
    });
  };

  useEffect(() => {
    getProducers();
  }, [currentPage]);

  const handleNewPage = async (_, newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const adminButtons = (id, theme) => {
    const baseUrl = getBaseUrlByTheme(theme);

    if (permissions.isSeller(user.role))
      return [{ text: 'Ver Eventos', link: `/backoffice/productoras/${id}` }];

    return [
      {
        text: 'Ver Productora',
        link: `${baseUrl}/productora/eventos/${id}`,
        buttonVariant: 'alternative',
      },
      { text: 'Manejar Productora', link: `/backoffice/productoras/${id}` },
    ];
  };

  return (
    <PageContainer user={user} title="Backoffice - Productoras">
      <div className={classes.pageContainer}>
        <div className={classes.producersContainer}>
          <Typography variant="h3">Productoras</Typography>
          <Searcher onSubmit={getProducers} placeholder="Buscar productora" />
          <LoadingListContent
            loading={loading}
            list={producers}
            notFoundMessage="No se encontraron productoras"
          >
            {producers.map((producer, index) => (
              <AdminCard
                key={index}
                producer={producer}
                title={producer.name}
                buttons={adminButtons(producer.id, producer.theme)}
                user={user}
              />
            ))}
            <Pagination count={totalPages} page={currentPage} onChange={handleNewPage} />
          </LoadingListContent>
        </div>
      </div>
    </PageContainer>
  );
};
export default withStyles(styles)(ProducerList);
