import { USER_ROLES } from './types';

export const OUT_OF_ARGENTINA_OPTION = 'Fuera de Argentina';

export const ARGENTINA_PROVINCES = [
  'Buenos Aires',
  'Ciudad Autónoma de Buenos Aires',
  OUT_OF_ARGENTINA_OPTION,
  'Catamarca',
  'Chaco',
  'Chubut',
  'Córdoba',
  'Corrientes',
  'Entre Ríos',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquén',
  'Río Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
  'Tucumán',
];

export const REGULAR_EXPRESSION = {
  MAIL_VALIDATION: /^[a-zA-Z0-9._%+-]+(\.[a-zA-Z0-9._%+-]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD_LENGHT_VALIDATION: /^.{8,}$/,
  PASSWORD_VALIDATION:
    /^(?=.*\d.*\d)(?=.*[#?!@$%^&*|°…^`~«≠ºª•¶§∞¢£“¡¬≤≥<>.¿'¡?}{+´\][()/"\\-_])(?=.*[a-z])(?=.*[A-Z]).*$/,
  TEXT_AND_NUMBERS_VALIDATION:
    /^[0-9a-zA-ZÀ-ÿ\\u00f1\\u00d1]+(\s*[0-9a-zA-ZÀ-ÿ\\u00f1\\u00d1]*)*[0-9a-zA-ZÀ-ÿ\\u00f1\\u00d1]+$/,
  TEXT_VALIDATION:
    /^[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+(\s*[a-zA-ZÀ-ÿ\\u00f1\\u00d1]*)*[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+$/,
  NUMBER_VALIDATION: /^[0-9]+$/,
  ALIAS_VALIDATION: /^[a-zA-Z0-9_-]+$/,
  DNI_VALIDATION: /^\d{7,8}$/,
  CUIL_VALIDATION: /^\d{11}$/,
  CDI_VALIDATION: /^\d{11}$/,
  CI_VALIDATION: /^\d{8}$/,
  PASSPORT_VALIDATION: /^[a-zA-Z0-9]+$/,
  LC_VALIDATION: /^\d{7}$/,
  LE_VALIDATION: /^\d{7}$/,
  DATE_VALIDATION: /^[0-9/]+$/,
  DATE_INFO_SPLITTER: /([^\s,]+),\s+(\d{2})\s+([^\s,]+)\s+(\d{4}),\s+(\d{2}:\d{2})/,
};

export const BLACKLISTABLE_ROLES = [
  USER_ROLES.SELLER,
  USER_ROLES.SELLER_WITH_CASH,
  USER_ROLES.READ_ONLY_ADMIN,
];
